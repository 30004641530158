import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MembershipModal from './MembershipModal/MembershipModal'; // Import the new modal component
import './Footer.css';

function Footer() {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    return (
        <>
            <div className="footer-container">
                <div className="footer">
                    <div className="footer-section">
                        <button onClick={openModal} className="link-button">
                            成为会员
                        </button>
                    </div>
                    <div className="footer-section">
                        <button onClick={() => navigate('/contact')} className="link-button">
                            联系我们
                        </button>
                    </div>
                    <div className="footer-section">
                        <button onClick={() => navigate('/faq')} className="link-button">
                            常见问题
                        </button>
                    </div>
                </div>
            </div>

            <MembershipModal visible={modalVisible} onClose={closeModal} />
        </>
    );
}

export default Footer;
