import { useState, useEffect, useCallback } from 'react';
import eventEmitter from './EventEmitter';
import serverAddress from "../../../ServerAddress";

const useChatbox = (location, chatId) => {
    const [responses, setResponses] = useState([]);
    const [isWaitingResponse, setIsWaitingResponse] = useState(false);
    const [isFirstMessage, setIsFirstMessage] = useState(true);
    const [isInitialMessageSent, setIsInitialMessageSent] = useState(false);

    const saveChatSession = async (data) => {
        try {
            const accessToken = localStorage.getItem('access_token');
            if (!accessToken) throw new Error('No access token found');

            const response = await fetch(`${serverAddress()}/recent/save_chat_session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const result = await response.json();
            console.log('Chat session saved successfully:', result);

            // Emit an event after saving the chat session
            //eventEmitter.emit('chatUpdated', data.user_id);
        } catch (error) {
            console.error('Error saving chat session:', error);
        }
    };

    const updateChatSession = async (data) => {
        try {
            const accessToken = localStorage.getItem('access_token');
            if (!accessToken) throw new Error('No access token found');
            const response = await fetch(`${serverAddress()}/recent/update_chat_session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const result = await response.json();
            console.log('Chat session updated successfully:', result);
        } catch (error) {
            console.error('Error updating chat session:', error);
        }
    };

    const saveTitle = async (data) => {
        try {
            const accessToken = localStorage.getItem('access_token');
            if (!accessToken) throw new Error('No access token found');

            const response = await fetch(`${serverAddress()}/recent/save_title`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) throw new Error('Network response was not ok');
            const result = await response.json();
            console.log('Title saved successfully:', result);
        } catch (error) {
            console.error('Error saving title:', error);
        }
    };

    const handleSendMessage = useCallback(async (messageToSend) => {
        if (messageToSend.trim()) {
            const mode = localStorage.getItem('mode') || 'net';
            const requestUrl = `${serverAddress()}/chat/use_collected_info?question=${encodeURIComponent(messageToSend)}&mode=${mode}`;
            console.log("Sending message to the server:", messageToSend);
            console.log("Sending request to:", requestUrl);
            console.log("sending mode is", mode)
            setIsWaitingResponse(true);

            const timestamp = Date.now();
            setResponses(responses => [...responses, { userMessage: messageToSend, text: "", urls: {}, timestamp }]);

            const eventSource = new EventSource(requestUrl);
            let accumulatedResponse = "";
            let accumulatedUrls = {};

            eventSource.onmessage = async function (event) {
                const data = event.data;

                if (data === 'END') {
                    console.log('Received END signal from server.');
                    console.log('Full accumulated response:', accumulatedResponse);
                    console.log('User message:', messageToSend);  // 记录每个问题
                    console.log('Associated URLs:', accumulatedUrls);  // Adding this line as requested

                    const userId = localStorage.getItem('user_id');
                    const initialQuery = location.state?.initialQuery || messageToSend;
                    const createTime = new Date().toISOString();
                    console.log("fetching user id is", userId);

                    if (isFirstMessage) {
                        const chatData = {
                            user_id: userId,
                            chat_id: chatId,
                            chats: [
                                {
                                    title: initialQuery,
                                    content: accumulatedResponse,
                                    urls: Object.keys(accumulatedUrls).map(key => ({
                                        title: key,
                                        url: accumulatedUrls[key]
                                    }))
                                }
                            ],
                            created_at: createTime
                        };

                        console.log('Chat data to save:', chatData);
                        await saveChatSession(chatData);

                        const titleData = {
                            user_id: userId,
                            chat_id: chatId,
                            title: initialQuery,
                            created_at: createTime
                        };
                        console.log('Title data to save:', titleData);
                        await saveTitle(titleData);

                        // Emit an event after saving the title
                        eventEmitter.emit('chatUpdated', userId);

                        setIsFirstMessage(false);
                    } else {
                        const chatUpdateData = {
                            user_id: userId,
                            chat_id: chatId,
                            title: messageToSend,
                            content: accumulatedResponse,
                            urls: Object.keys(accumulatedUrls).map(key => ({
                                title: key,
                                url: accumulatedUrls[key]
                            }))
                        };

                        console.log('Chat update data to save:', chatUpdateData);
                        await updateChatSession(chatUpdateData);
                    }

                    eventSource.close();
                    setIsWaitingResponse(false);
                    return;
                }

                try {
                    const parsedData = JSON.parse(data);
                    accumulatedResponse += parsedData.text || "";
                    if (parsedData.urls) {
                        accumulatedUrls = { ...accumulatedUrls, ...parsedData.urls };
                    }

                    setResponses(responses => {
                        const existingResponseIndex = responses.findIndex(r => r.userMessage === messageToSend && r.timestamp === timestamp);
                        if (existingResponseIndex >= 0) {
                            const updatedResponses = [...responses];
                            updatedResponses[existingResponseIndex] = {
                                ...updatedResponses[existingResponseIndex],
                                text: updatedResponses[existingResponseIndex].text + (parsedData.text || ""),
                                urls: { ...updatedResponses[existingResponseIndex].urls, ...parsedData.urls }
                            };
                            return updatedResponses;
                        } else {
                            return [...responses, { userMessage: messageToSend, text: parsedData.text || "", urls: parsedData.urls, timestamp }];
                        }
                    });
                } catch (e) {
                    console.error('Error parsing message data:', e);
                }
            };

            eventSource.onerror = function(event) {
                console.error('EventSource encountered an error:', event);
                eventSource.close();
                setIsWaitingResponse(false);
            };

            eventSource.onopen = function() {
                console.log("Connection to server opened.");
            };

            return () => {
                eventSource.close();
            };
        }
    }, [location.state?.initialQuery, isFirstMessage, chatId]);

    useEffect(() => {
        const initialQuery = location.state?.initialQuery;
        if (initialQuery && !isInitialMessageSent) {
            handleSendMessage(initialQuery);
            setIsInitialMessageSent(true);
        }
    }, [location.state, handleSendMessage, isInitialMessageSent]);

    useEffect(() => {
        setResponses([]); // 清空 responses
        console.log("Responses have been cleared");
    }, [location.pathname]);

    return {
        responses,
        isWaitingResponse,
        handleSendMessage,
        isInitialMessageSent,
        setIsFirstMessage // 将 setIsFirstMessage 暴露出来
    };
};

export default useChatbox;
