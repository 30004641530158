import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import './CalendarPicker.css'; // Import the CSS styles

const CalendarPicker = ({ onDateSelect, position, maxDate, minDate, hideCalendar }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const calendarRef = useRef(null);
    const startDate = minDate ? new Date(minDate) : new Date('2024-08-31T00:00:00+08:00'); // 定义开始日期

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                hideCalendar(); // Close the calendar if clicked outside
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [calendarRef, hideCalendar]);

    const [today] = useState(new Date());

    const changeMonth = (delta) => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + delta);
        setCurrentDate(newDate);
    };

    const selectDate = (date) => {
        const beijingTime = new Date(date.getTime() + 8 * 60 * 60 * 1000); // 加8小时
        if (date >= startDate && date <= today && (!maxDate || date <= new Date(maxDate))) {
            const formattedDate = beijingTime.toISOString().slice(0, 10); // 格式化为 YYYY-MM-DD
            onDateSelect(formattedDate);
            hideCalendar(); // Hide calendar after selecting a valid date
        }
    };

    const renderCalendar = () => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();

        const firstDay = new Date(year, month, 1).getDay();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const daysOfWeek = ['日', '一', '二', '三', '四', '五', '六'];

        const daysGrid = [];
        for (let i = 0; i < firstDay; i++) {
            daysGrid.push(<span key={`empty-${i}`}></span>);
        }
        for (let day = 1; day <= daysInMonth; day++) {
            const currentDay = new Date(year, month, day);
            const isDisabled = currentDay < startDate || currentDay > today || (maxDate && currentDay > new Date(maxDate));
            daysGrid.push(
                <span
                    key={day}
                    onClick={() => !isDisabled && selectDate(currentDay)}
                    style={{ color: isDisabled ? 'lightgray' : 'black', cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                >
                    {day}
                </span>
            );
        }

        return (
            <Box
                className="calendar-picker"
                ref={calendarRef}
                style={{
                    position: 'absolute',
                    top: position.top,
                    left: position.left,
                }}
            >
                <Box className="header">
                    <button className="prev-month" onClick={() => changeMonth(-1)}>&lt;</button>
                    <Typography>{`${year}年${month + 1}月`}</Typography>
                    <button className="next-month" onClick={() => changeMonth(1)}>&gt;</button>
                </Box>
                <Box className="weekday-header">
                    {daysOfWeek.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </Box>
                <Box className="days-grid">{daysGrid}</Box>
            </Box>
        );
    };

    return renderCalendar();
};

export default CalendarPicker;
