import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Tabs, Tab, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StarIcon from '@mui/icons-material/Star';
import DiamondIcon from '@mui/icons-material/Diamond';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const PaymentOption = ({ title, price, description, isSelected, onClick, icon }) => (
    <Box
        onClick={onClick}
        sx={{
            border: '1px solid',
            borderColor: isSelected ? '#89c9ff' : '#d1d1d1',
            borderRadius: '8px',
            padding: '16px',
            cursor: 'pointer',
            transition: 'all 0.3s ease-in-out',
            backgroundColor: isSelected ? '#e6f5ff' : '#f0f8ff',
            boxShadow: isSelected ? '0 4px 8px rgba(137, 201, 255, 0.1)' : 'none',
            '&:hover': {
                borderColor: '#89c9ff',
            },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}
    >
        <Box display="flex" alignItems="center">
            {icon && <Box sx={{ marginRight: '12px', color: '#007bff' }}>{icon}</Box>}
            <Box>
                <Typography variant="h6" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600, color: '#007bff' }}>
                    {title}
                </Typography>
                <Box>{description}</Box>
            </Box>
        </Box>
        <Typography variant="h5" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700, color: '#343a40' }}>
            ¥{price}
        </Typography>
    </Box>
);

const MembershipModal = ({ visible, onClose }) => {
    useEffect(() => {
        if (visible) {
            document.body.style.overflow = 'hidden'; // 隐藏滚动条
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = ''; // 恢复滚动条
        };
    }, [visible]);

    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedCredit, setSelectedCredit] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const creditOptions = [
        {
            title: '400积分',
            price: '30',
            description: '适合轻度用户',
            icon: <MonetizationOnIcon />
        },
        {
            title: '800积分',
            price: '50',
            description: '最受欢迎',
            icon: <StarIcon />
        },
        {
            title: '2000积分',
            price: '90',
            description: '最具性价比',
            icon: <DiamondIcon />
        }
    ];

    const planOptions = [
        {
            title: '免费会员',
            price: '0',
            description: (
                <>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        每天尊享 3 次免费 AI 搜索引擎服务，体验智能搜索
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        每天 3 次免费学习平台访问，提升专业技能和知识
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        每天 3 次免费 PDF 财报分析，深入洞察市场动态
                    </Typography>
                </>
            ),
            icon: <FreeBreakfastIcon />
        },
        {
            title: '基础会员',
            price: '30',
            description: (
                <>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        每天尊享 10 次 AI 搜索引擎服务，精准获取所需信息
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        每天 10 次 学习平台提问，快速提升专业技能和知识
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        每天 10 次 PDF 财报分析，深入洞察市场动态
                    </Typography>
                </>
            ),
            icon: <WalletIcon />
        },
        {
            title: '高级会员',
            price: '80',
            description: (
                <>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        每天高达 100 次 AI 搜索引擎和 50 次财报分析及 50 次学习平台提问
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        解锁公司趋势分析高级功能，洞察市场走向，抢占先机
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
                        使用更长上下文的 GPT 模型，享受更智能的对话体验
                    </Typography>
                </>
            ),
            icon: <DiamondIcon />
        }
    ];

    const creditInfo = (
        <Box mt={2} mb={2} textAlign="center">
            <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
                积分使用指南：
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, color: '#6c757d' }}>
                每次财报分析：仅需 1 积分，轻松获取关键数据
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, color: '#6c757d' }}>
                每次学习平台使用：1 积分，让您的学习更高效
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500, color: '#6c757d' }}>
                每次智投引擎使用：1 积分，及时获取最新咨询
            </Typography>
        </Box>
    );

    return (
        <Dialog open={visible} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f0f8ff' }}>
                <Typography variant="h6" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                    会员中心
                </Typography>
                <IconButton edge="end" color="inherit" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: '#f0f8ff' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    centered
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            borderBottom: '1px solid #e0e0e0',
                        },
                        '& .MuiTab-root': {
                            fontFamily: 'Poppins, sans-serif',
                            fontWeight: 600,
                            color: '#007bff',
                            '&.Mui-selected': {
                                color: '#007bff',
                            },
                        },
                    }}
                >
                    <Tab icon={<WalletIcon />} label="购买积分" />
                    <Tab icon={<CreditCardIcon />} label="会员订阅" />
                </Tabs>
                <Box mt={3}>
                    {selectedTab === 0 && (
                        <>
                            {creditInfo}
                            <Box display="flex" flexDirection="column" gap={2}>
                                {creditOptions.map((option, index) => (
                                    <PaymentOption
                                        key={index}
                                        {...option}
                                        isSelected={selectedCredit === index}
                                        onClick={() => setSelectedCredit(index)}
                                    />
                                ))}
                            </Box>
                        </>
                    )}
                    {selectedTab === 1 && (
                        <Box display="flex" flexDirection="column" gap={2}>
                            {planOptions.map((option, index) => (
                                <PaymentOption
                                    key={index}
                                    {...option}
                                    isSelected={selectedPlan === index}
                                    onClick={() => setSelectedPlan(index)}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
                <Box mt={4} display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            textTransform: 'none',
                            background: '#007bff',
                            color: '#fff',
                            borderRadius: '8px',
                            padding: '12px 24px',
                            '&:hover': {
                                background: '#0056b3',
                            },
                        }}
                        onClick={onClose}
                    >
                        使用微信支付
                    </Button>
                </Box>
                <Typography variant="body2" color="textSecondary" align="center" mt={2} sx={{ fontFamily: 'Poppins, sans-serif' }}>
                    点击按钮后，将跳转至微信支付
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default MembershipModal;
