import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import HistoryIcon from '@mui/icons-material/History';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolIcon from '@mui/icons-material/School';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import RecentChats from '../RecentChats/RecentChats';
import LoginRegisterModal from './LoginRegisterModal/LoginRegisterModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scrollbar.css';
import serverAddress from "../../ServerAddress";
import SidebarHeader from './SidebarHeader'; // 引入 SidebarHeader 组件
import UserProfile from './UserProfile'; // 引入 UserProfile 组件
import { useTheme } from '@mui/material/styles';

const colorScheme = {
    backgroundGradient: 'linear-gradient(90deg, #f0f8ff 0%, #d6eaff 100%)',
    selectedBackground: 'rgba(255, 255, 255, 0.3)',
    textColor: '#000000',
    iconColor: '#000000',
    hoverBackground: 'rgba(255, 255, 255, 0.2)',
};

function Sidebar({ isOpen, toggleSidebar, isLoggedIn, setIsLoggedIn, username, setUsername }) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedModule, setSelectedModule] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(true);
    const [autoCollapse, setAutoCollapse] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768 && autoCollapse) {
                if (isOpen) toggleSidebar();
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isOpen, toggleSidebar, autoCollapse]);

    useEffect(() => {
        const verifyToken = async () => {
            const token = localStorage.getItem('access_token');
            if (token) {
                try {
                    const response = await axios.post(`${serverAddress()}/auth/verify_token`, {}, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response.data.message === 'Token verified successfully!') {
                        setIsLoggedIn(true);
                        setUsername(response.data.username);
                    } else {
                        setIsLoggedIn(false);
                    }
                } catch (err) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('username');
                    setIsLoggedIn(false);
                }
            } else {
                setIsLoggedIn(false);
            }
            setLoading(false);
        };

        verifyToken();
    }, [setIsLoggedIn, setUsername]);

    const handleOpenModal = () => {
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setError('');
        setSuccess('');
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setUsername('');
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('username');
        handleMenuClose();
    };

    const displayUsername = (name) => {
        return name.length > 7 ? `${name.substring(0, 7)}...` : name;
    };

    const handleModuleClick = (module) => {
        setSelectedModule(module);
    };

    const handleToggleSidebar = () => {
        setAutoCollapse(false);
        toggleSidebar();
    };

    const menuItems = [
        {
            text: '首页',
            icon: <HomeIcon fontSize="medium" />,
            to: '/search',
            module: 'home',
        },
        {
            text: '大盘新闻',
            icon: <PublicIcon fontSize="medium" />,
            to: '/hotnews',
            module: 'news',
        },
        {
            text: '公司',
            icon: <DescriptionIcon fontSize="medium" />,
            to: '/analysis',
            module: 'company',
        },
        {
            text: '学习',
            icon: <SchoolIcon fontSize="medium" />,
            to: '/learn',
            module: 'learn',
        },
    ];

    const recentChatsMaxHeight = 200;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowX: 'hidden' }}>
            <Drawer
                variant="permanent"
                anchor="left"
                open={isOpen}
                className="scrollbar-container"
                sx={{
                    width: isOpen ? 240 : 56,
                    flexShrink: 0,
                    height: '100vh',
                    overflowX: 'hidden',
                    overflowY: isOpen ? 'auto' : 'hidden',
                    '& .MuiDrawer-paper': {
                        width: isOpen ? 240 : 56,
                        overflowX: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        background: colorScheme.backgroundGradient, // 使用 SidebarMenu 的背景颜色
                        height: '100vh',
                        overflowY: isOpen ? 'auto' : 'hidden',
                    }
                }}
            >
                <SidebarHeader isOpen={isOpen} handleToggleSidebar={handleToggleSidebar} />

                <Box className="scrollbar-container" sx={{ flexGrow: 1, overflowX: 'hidden', overflowY: isOpen ? 'auto' : 'hidden' }}>
                    <List
                        sx={{
                            background: colorScheme.backgroundGradient,
                            color: colorScheme.textColor,
                            height: '100%',
                        }}
                    >
                        {menuItems.map((item) => (
                            <ListItem
                                button
                                key={item.text}
                                component={RouterLink}
                                to={item.to}
                                sx={{
                                    mb: 1,
                                    backgroundColor: selectedModule === item.module ? colorScheme.selectedBackground : 'transparent',
                                    transition: theme.transitions.create(['background-color', 'transform'], {
                                        duration: theme.transitions.duration.standard,
                                    }),
                                    boxShadow: selectedModule === item.module ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
                                    '&:hover': {
                                        transform: 'scale(1.02)',
                                        backgroundColor: colorScheme.hoverBackground, // 修改为指定的 hover 背景颜色
                                        transition: 'transform 0.2s ease, background-color 0.2s ease',
                                    },
                                    borderLeft: selectedModule === item.module ? '4px solid #000000' : 'none',
                                }}
                                onClick={() => handleModuleClick(item.module)}
                            >
                                <ListItemIcon sx={{ minWidth: 48, color: colorScheme.iconColor }}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.text}
                                    sx={{
                                        ml: -1,
                                        fontSize: '1.1rem',
                                        fontWeight: '500',
                                        whiteSpace: 'nowrap',
                                        opacity: isOpen ? 1 : 0,
                                        width: isOpen ? 'auto' : 0,
                                        transition: theme.transitions.create(['opacity', 'width'], {
                                            duration: theme.transitions.duration.standard,
                                        }),
                                        overflow: 'hidden',
                                    }}
                                />
                            </ListItem>

                        ))}

                        <ListItem
                            button
                            sx={{
                                mb: 1,
                                backgroundColor: selectedModule === 'recent' ? colorScheme.selectedBackground : 'transparent',
                                transition: theme.transitions.create(['background-color', 'transform'], {
                                    duration: theme.transitions.duration.standard,
                                }),
                                boxShadow: selectedModule === 'recent' ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    backgroundColor: colorScheme.hoverBackground, // 修改为指定的 hover 背景颜色
                                    transition: 'transform 0.2s ease, background-color 0.2s ease',
                                },
                                borderLeft: selectedModule === 'recent' ? '4px solid #000000' : 'none',
                            }}
                            onClick={() => handleModuleClick('recent')}
                        >
                            <ListItemIcon sx={{ minWidth: 48, color: colorScheme.iconColor }}>
                                <HistoryIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText
                                primary="最近"
                                sx={{
                                    ml: -1,
                                    fontSize: '1.1rem',
                                    fontWeight: '500',
                                    whiteSpace: 'nowrap',
                                    opacity: isOpen ? 1 : 0,
                                    width: isOpen ? 'auto' : 0,
                                    transition: theme.transitions.create(['opacity', 'width'], {
                                        duration: theme.transitions.duration.standard,
                                    }),
                                    overflow: 'hidden',
                                }}
                            />
                        </ListItem>


                        {isOpen && (
                            <Box
                                sx={{
                                    pl: 4,
                                    opacity: 1,
                                    maxHeight: recentChatsMaxHeight,
                                    transition: theme.transitions.create(['opacity', 'max-height'], {
                                        duration: theme.transitions.duration.standard,
                                        easing: theme.transitions.easing.easeOut,
                                    }),
                                    overflow: 'hidden',
                                }}
                            >
                                {/* 移除外围的 Box 样式 */}
                                <RecentChats colorScheme={colorScheme} />
                            </Box>
                        )}

                    </List>
                </Box>

                <UserProfile
                    isOpen={isOpen}
                    isLoggedIn={isLoggedIn}
                    loading={loading}
                    username={username}
                    handleAvatarClick={handleAvatarClick}
                    anchorEl={anchorEl}
                    handleMenuClose={handleMenuClose}
                    handleLogout={handleLogout}
                    handleOpenModal={handleOpenModal}
                    displayUsername={displayUsername}
                />
            </Drawer>
            <LoginRegisterModal
                open={isModalOpen}
                onClose={handleCloseModal}
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                error={error}
                setError={setError}
                success={success}
                setSuccess={setSuccess}
                setIsLoggedIn={setIsLoggedIn}
            />
        </div>
    );
}

export default Sidebar;
