import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Grid, CardContent, Snackbar, Alert, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { StyledCard, BookCover, CompanyLogo, Header, SearchBox } from '../../Style/BookStyle';
import serverAddress from "../../../ServerAddress"; // Ensure the correct path
import { PuffLoader } from 'react-spinners'; // 引入 PuffLoader

function FavoritesPage() {
    const [loading, setLoading] = useState(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage] = useState('');
    const [snackbarSeverity] = useState('success');
    const [favoriteBooks, setFavoriteBooks] = useState([]);
    const [favoriteCompanies, setFavoriteCompanies] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [prevToken, setPrevToken] = useState(localStorage.getItem('access_token'));
    const navigate = useNavigate();

    const useInterval = (callback, delay) => {
        const savedCallback = useRef();

        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    };

    const fetchFavoriteItems = async () => {
        const userId = localStorage.getItem('user_id');
        if (!userId) {
            console.error('User ID not found in local storage.');
            setLoading(false);
            return;
        }

        const token = localStorage.getItem('access_token'); // 获取最新的 JWT token

        try {
            const [booksResponse, companiesResponse] = await Promise.all([
                fetch(`${serverAddress()}/user/get_favorites/${userId}/book`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // 在请求头中包含JWT token
                    }
                }),
                fetch(`${serverAddress()}/user/get_favorites/${userId}/company`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // 在请求头中包含JWT token
                    }
                })
            ]);

            if (!booksResponse.ok || !companiesResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const booksData = await booksResponse.json();
            const companiesData = await companiesResponse.json();

            setFavoriteBooks(booksData.items);
            setFavoriteCompanies(companiesData.items);
        } catch (error) {
            console.error('Failed to fetch favorite items:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFavoriteItems();
    }, []);

    useEffect(() => {
        const preloadImages = () => {
            const images = [
                ...favoriteBooks.map(book => `/BookImages/${book.item_name}.jpg`),
                ...favoriteCompanies.map(company => `/CompanyImages/${company.item_name}.png`)
            ].map(src =>
                new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = src;
                    img.onload = resolve;
                    img.onerror = reject;
                })
            );

            Promise.all(images)
                .then(() => setImagesLoaded(true))
                .catch(err => console.error('Error loading images', err));
        };

        if (favoriteBooks.length > 0 || favoriteCompanies.length > 0) {
            preloadImages();
        } else {
            setImagesLoaded(true); // No images to load, mark as loaded
        }
    }, [favoriteBooks, favoriteCompanies]);

    useInterval(() => {
        const token = localStorage.getItem('access_token');
        console.log('Current access_token:', token);
        if (!token) {
            setFavoriteBooks([]);
            setFavoriteCompanies([]);
        } else if (token !== prevToken) {
            setPrevToken(token);
            fetchFavoriteItems();
        }
    }, 500); // 每秒检查一次

    const handleBookClick = async (book) => {
        setLoading(true);
        const token = localStorage.getItem('access_token'); // 获取最新的 JWT token

        try {
            const response = await fetch(`${serverAddress()}/p/load_pdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // 在请求头中包含JWT token
                },
                credentials: 'include', // 确保包含cookie
                body: JSON.stringify({ filename: book.item_name }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.message === 'File loaded and model created.') {
                navigate(`/pdf-viewer/${encodeURIComponent(book.item_name)}`);
            } else {
                console.error('Failed to load PDF:', data);
            }
        } catch (error) {
            console.error('Failed to load PDF:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCompanyClick = (company) => {
        const companyWithCode = `${company.item_name} (${company.stock_code})`;
        navigate(`/company-analysis/${encodeURIComponent(companyWithCode)}`);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredBooks = favoriteBooks.filter((book) =>
        book.item_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredCompanies = favoriteCompanies.filter((company) =>
        company.item_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const renderBooksGrid = () => {
        const bookItems = filteredBooks.map((book) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={book.item_name}>
                <StyledCard sx={{ minWidth: '280px', maxWidth: '320px' }} onClick={() => handleBookClick(book)}>
                    <BookCover>
                        <img src={`/BookImages/${book.item_name}.jpg`} alt={book.item_name} />
                    </BookCover>
                    <CardContent>
                        <Typography variant="body1" gutterBottom>
                            {book.item_name}
                        </Typography>
                    </CardContent>
                </StyledCard>
            </Grid>
        ));

        // 添加占位符项以保持网格布局
        while (bookItems.length < 4 || bookItems.length % 4 !== 0) {
            bookItems.push(
                <Grid item xs={12} sm={6} md={4} lg={3} key={`book-placeholder-${bookItems.length}`} sx={{ visibility: 'hidden' }}>
                    <StyledCard sx={{ minWidth: '280px', maxWidth: '320px' }}>
                        <BookCover>
                            <img src={`/images/placeholder.jpg`} alt="placeholder" />
                        </BookCover>
                        <CardContent>
                            <Typography variant="body1" gutterBottom>
                                Placeholder
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
            );
        }

        return bookItems;
    };

    const renderCompaniesGrid = () => {
        const companyItems = filteredCompanies.map((company) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={company.item_name}>
                <StyledCard sx={{ minWidth: '280px', maxWidth: '320px' }} onClick={() => handleCompanyClick(company)}>
                    <CompanyLogo>
                        <img src={`/CompanyImages/${company.item_name}.png`} alt={company.item_name} />
                    </CompanyLogo>
                    <CardContent>
                        <Typography variant="body1" gutterBottom>
                            {company.item_name} ({company.stock_code})
                        </Typography>
                    </CardContent>
                </StyledCard>
            </Grid>
        ));

        // 添加占位符项以保持网格布局
        while (companyItems.length < 4 || companyItems.length % 4 !== 0) {
            companyItems.push(
                <Grid item xs={12} sm={6} md={4} lg={3} key={`company-placeholder-${companyItems.length}`} sx={{ visibility: 'hidden' }}>
                    <StyledCard sx={{ minWidth: '280px', maxWidth: '320px' }}>
                        <CompanyLogo>
                            <img src={`/images/placeholder.jpg`} alt="placeholder" />
                        </CompanyLogo>
                        <CardContent>
                            <Typography variant="body1" gutterBottom>
                                Placeholder
                            </Typography>
                        </CardContent>
                    </StyledCard>
                </Grid>
            );
        }

        return companyItems;
    };

    const renderPlaceholderBox = (text, onClickHandler) => (
        <Box
            onClick={onClickHandler}
            sx={{
                border: '2px dashed #1E90FF', // 较亮的蓝色边框
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 150,
                width: 150,
                marginTop: 2,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
                '&:hover': {
                    backgroundColor: 'rgba(30, 144, 255, 0.1)', // 悬停效果
                },
            }}
        >
            <AddIcon sx={{ color: '#1E90FF', fontSize: 40 }} />
        </Box>
    );

    if (loading || !imagesLoaded) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#f0f8ff', // 背景颜色
                    textAlign: 'center',
                    gap: 3, // 控制文字与动画之间的间距
                }}
            >
                <PuffLoader color="#4682b4" size={120} /> {/* 替换为 PuffLoader */}
                <Typography variant="h6" sx={{ color: '#4682b4' }}>
                    正在加载，请稍候...
                </Typography>
                <Typography variant="body2" sx={{ color: '#4682b4', opacity: 0.8 }}>
                    我们正在为您加载收藏信息
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ padding: 4, maxWidth: '1200px', margin: '0 auto' }}>
            <Header>
                <Typography variant="h4" gutterBottom>
                    已收藏
                </Typography>
                <SearchBox
                    variant="outlined"
                    placeholder="搜索收藏"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ maxWidth: '400px' }} // 限制搜索框的最大宽度
                />
            </Header>
            <Box sx={{ marginBottom: 4, width: '100%' }}>
                <Typography variant="h5" gutterBottom>
                    已收藏的公司
                </Typography>
                {favoriteCompanies.length === 0 && (
                    <Box>
                        {renderPlaceholderBox('添加公司', () => navigate('/analysis'))}
                    </Box>
                )}
                <Grid container spacing={1}>
                    {renderCompaniesGrid()}
                </Grid>
            </Box>
            <Box sx={{ marginBottom: 4, width: '100%' }}>
                <Typography variant="h5" gutterBottom>
                    已收藏的书籍
                </Typography>
                {favoriteBooks.length === 0 && (
                    <Box>
                        {renderPlaceholderBox('添加书籍', () => navigate('/learn'))}
                    </Box>
                )}
                <Grid container spacing={1}>
                    {renderBooksGrid()}
                </Grid>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default FavoritesPage;
