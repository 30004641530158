import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, List, ListItem, CircularProgress, Avatar } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import axios from "axios";
import './PdfChatbox.css';
import serverAddress from "../../ServerAddress"; // Import the CSS file

axios.defaults.withCredentials = true;

const PdfChatbox = ({ mode }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isWaitingResponse, setIsWaitingResponse] = useState(false);
    const [firstCharReceived, setFirstCharReceived] = useState(false);
    const messagesEndRef = useRef(null);

    const handleSend = async () => {
        if (input.trim() && !isWaitingResponse) {
            setIsWaitingResponse(true);
            setFirstCharReceived(false);
            setMessages([...messages, { text: input, user: true }]);
            setInput('');

            const response = await fetch(`${serverAddress()}/p/ask_question?question=${encodeURIComponent(input)}&mode=${mode}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'text/event-stream'
                }
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let newMessage = '';
            let boldOpen = false;

            const readStream = async () => {
                const { done, value } = await reader.read();
                if (done) {
                    setIsWaitingResponse(false);
                    return;
                }
                const chunk = decoder.decode(value, { stream: true });

                chunk.split('').forEach(char => {
                    if (!firstCharReceived) {
                        setFirstCharReceived(true);
                    }

                    if (char === '*' && newMessage.endsWith('*')) {
                        boldOpen = !boldOpen;
                        newMessage = newMessage.slice(0, -1) + (boldOpen ? '<strong>' : '</strong>');
                    } else {
                        newMessage += char;
                    }
                });

                setMessages(prevMessages => {
                    const updatedMessages = [...prevMessages];
                    if (updatedMessages.length > 0 && updatedMessages[updatedMessages.length - 1].user === false) {
                        updatedMessages[updatedMessages.length - 1].text = newMessage;
                    } else {
                        updatedMessages.push({ text: newMessage, user: false });
                    }
                    return updatedMessages;
                });
                await readStream();
            };

            readStream().catch(error => {
                console.error('遇到问题，无法获取答案。请稍后再试。', error);
                setMessages(prevMessages => [...prevMessages, { text: '遇到问题，无法获取答案。请稍后再试。', user: false }]);
                setIsWaitingResponse(false);
            });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !isWaitingResponse) {
            e.preventDefault();
            handleSend();
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                marginLeft: { xs: '40px', md: 0 } // Add left margin on mobile devices only
            }}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    marginBottom: '10px',
                    backgroundColor: '#f0f0f0'
                }}
                className="scrollable-container"
            >
                <List sx={{ padding: 0 }}>
                    {messages.map((message, index) => (
                        <ListItem key={index} sx={{ display: 'flex', justifyContent: message.user ? 'flex-end' : 'flex-start', padding: '2px 0' }}>
                            <Box
                                sx={{
                                    maxWidth: message.user ? '70%' : '100%',
                                    padding: '8px 12px', // 适当调整内边距，保持空间平衡
                                    borderRadius: '25px',
                                    backgroundColor: message.user ? '#007bff' : '#e0e0e0',
                                    color: message.user ? '#fff' : '#000',
                                    margin: 0,
                                    wordWrap: 'break-word',
                                    fontSize: '0.875rem',
                                    lineHeight: '1.5', // 设置合适的行高
                                    whiteSpace: 'normal',
                                    display: 'block', // 确保上下排列
                                }}
                            >
                                {message.user ? (
                                    message.text
                                ) : (
                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]}
                                        components={{
                                            p: ({node, ...props}) => <p style={{ margin: 0, padding: 0 }} {...props} />, // 覆盖 <p> 标签的默认 margin 和 padding
                                            span: ({node, ...props}) => <span style={{ margin: 0, padding: 0 }} {...props} />, // 同样覆盖 <span> 标签的默认 margin 和 padding
                                        }}
                                    >
                                        {message.text}
                                    </ReactMarkdown>
                                )}
                            </Box>

                        </ListItem>
                    ))}
                    {isWaitingResponse && !firstCharReceived && (
                        <ListItem sx={{ display: 'flex', justifyContent: 'flex-start', padding: '10px 0' }}>
                            <CircularProgress size={20} />
                        </ListItem>
                    )}
                    <div ref={messagesEndRef} />
                </List>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <TextField
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                    fullWidth
                    placeholder="输入消息..."
                    sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '25px',
                    }}
                    InputProps={{
                        endAdornment: (
                            <Box
                                position="absolute"
                                right="15px"
                                bottom="15px"
                                transform="translateY(-50%)"
                                zIndex="1"
                                onClick={!isWaitingResponse && input.trim() ? handleSend : null}
                                sx={{ cursor: isWaitingResponse ? 'not-allowed' : 'pointer' }}
                            >
                                <Avatar sx={{ width: 24, height: 24, bgcolor: isWaitingResponse || input.trim() ? 'black' : 'gray' }}>
                                    {isWaitingResponse ? <HourglassEmptyIcon /> : <ArrowForwardIcon />}
                                </Avatar>
                            </Box>
                        ),
                        style: {
                            borderRadius: '25px'
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default PdfChatbox;
