import React from 'react';
import { Box, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const RenderResearchItem = ({ item, displayName, onReportClick }) => {
    const [imageError, setImageError] = React.useState(false);  // 新增状态来处理图片加载失败

    const handleImageError = () => {
        setImageError(true);  // 如果图片加载失败，将状态设为 true
    };

    return (
        <Box
            key={item.id}
            sx={{
                marginBottom: 2,
                border: '1px solid #ddd',
                borderRadius: '8px',  // 添加圆角
                padding: '16px',  // 添加内边距
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',  // 添加阴影
                cursor: 'pointer',
                transition: 'all 0.3s ease',  // 平滑过渡效果
                '&:hover': {
                    backgroundColor: '#f5f5f5',  // hover 背景颜色
                    transform: 'translateY(-2px)',  // 轻微上移
                    transition: 'all 0.3s ease'  // 过渡效果
                },
                '&:active': {
                    backgroundColor: '#e0e0e0',  // 点击反馈背景颜色
                    transition: 'background-color 0.2s ease'
                }
            }}
            onClick={() => onReportClick(item)}
        >

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{item.title}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                        <Typography variant="body2" sx={{ color: '#888' }}>{item.company_name}</Typography>
                        <Typography variant="body2" sx={{ margin: '0 4px', fontSize: '1.5rem' }}>·</Typography>
                        <Typography variant="body2" sx={{ color: '#888' }}>{item.publish_time}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        marginRight: '8px',
                        border: '1px solid #ddd',  // 增加边框
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'  // 添加轻微阴影
                    }}>
                        {imageError ? (
                            <Skeleton variant="circular" width={40} height={40} />  // 当图片加载失败时显示骨架屏
                        ) : (
                            <img
                                src={`/CompanyImages/${displayName}.png`}
                                alt={displayName}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                onError={handleImageError}
                            />
                        )}
                    </Box>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{displayName}</Typography> {/* 加粗公司名字 */}
                </Box>
            </Box>
        </Box>
    );
};

export default RenderResearchItem;
