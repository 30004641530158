// TrendDataRender.jsx

import React from 'react';
import {
    Box,
    Paper,
    Link,
    Divider,
    Typography,
} from '@mui/material';
import { PieChart, Warning } from '@mui/icons-material';
import CompanySection from './CompanySection/CompanySection'; // 导入公司的组件
import MarketSection from './MarketPerformanceSection/MarketSection'; // 导入市场表现组件
import ResearchReportSection from './ ResearchReportSection/ResearchReportSection'; // 导入研究报告组件
import ReactMarkdown from 'react-markdown';

const TrendDataRender = ({ trendData, stockType, cleanCompanyName }) => {
    const {
        CompanyProfile,
        CompanyProfileUpdateTime, // 新增这个字段
        MarketPerformance,
        MarketPerformanceUpdateTime,
        ShareholderStructure,
        ShareholderStructureUpdateTime,
        ResearchReport,
        ResearchReportUpdateTime,
        RiskAssessment,
        RiskAssessmentUpdateTime,
        GraphData,
        SelectedTitles = [],
    } = trendData;
    console.log('trendData:', trendData);

    const formatDate = (dateString) => {
        if (!dateString) return '无更新信息';
        const date = new Date(dateString);
        return `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };

    // 检查是否显示研究报告的导航项
    const shouldShowResearchReport = GraphData && GraphData.RevenueAndNetIncomeGrowth.length > 0 && SelectedTitles.length > 0;

    return (
        <Box sx={{ p: { xs: 1, md: 3 }, mt: -3 }}>
            {/* 顶部导航 */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    p: 2,
                    mb: 4,
                    backgroundColor: '#f0f4f8',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    fontWeight: 'bold',
                }}
            >
                <Link href="#companyProfile" color="inherit" sx={{ textDecoration: 'none' }}>
                    公司概况
                </Link>
                <Link href="#marketPerformance" color="inherit" sx={{ textDecoration: 'none' }}>
                    市场表现
                </Link>
                <Link href="#shareholderStructure" color="inherit" sx={{ textDecoration: 'none' }}>
                    股东结构
                </Link>
                {shouldShowResearchReport && (
                    <Link href="#researchReport" color="inherit" sx={{ textDecoration: 'none' }}>
                        研究报告
                    </Link>
                )}
                <Link href="#riskAssessment" color="inherit" sx={{ textDecoration: 'none' }}>
                    风险评估
                </Link>
            </Box>
            {/* 公司概况 */}
            <CompanySection
                CompanyProfile={CompanyProfile}
                CompanyProfileUpdateTime={CompanyProfileUpdateTime} // 传递更新日期
                formatDate={formatDate}
                GraphData={GraphData}
            />


            {/* 市场表现 */}
            <MarketSection
                MarketPerformance={MarketPerformance}
                MarketPerformanceUpdateTime={MarketPerformanceUpdateTime}
                formatDate={formatDate}
                GraphData={GraphData}
            />

            {/* 股东结构 */}
            <Paper
                id="shareholderStructure"
                elevation={2}
                sx={{
                    p: 3,
                    borderRadius: '12px',
                    backgroundColor: '#f7f7f7',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                    mb: 4,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <PieChart sx={{ mr: 1, color: '#4caf50', fontSize: '28px' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                            股东结构
                        </Typography>
                    </Box>
                    <Typography variant="caption" color="textSecondary">
                        更新日期：{formatDate(ShareholderStructureUpdateTime)}
                    </Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <ReactMarkdown>{ShareholderStructure || '暂无股东结构信息'}</ReactMarkdown>
            </Paper>

            {/* 研究报告部分 */}
            {shouldShowResearchReport && (
                <ResearchReportSection
                    ResearchReport={ResearchReport}
                    ResearchReportUpdateTime={ResearchReportUpdateTime}
                    formatDate={formatDate}
                    SelectedTitles={SelectedTitles}
                    cleanCompanyName={cleanCompanyName}
                    stockType={stockType}
                    GraphData={GraphData}
                />
            )}

            {/* 风险评估 */}
            <Paper
                id="riskAssessment"
                elevation={2}
                sx={{
                    p: 3,
                    borderRadius: '12px',
                    backgroundColor: '#f7f7f7',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                    mb: 4,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Warning sx={{ mr: 1, color: '#ff9800', fontSize: '28px' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ff9800' }}>
                            风险评估
                        </Typography>
                    </Box>
                    <Typography variant="caption" color="textSecondary">
                        更新日期：{formatDate(RiskAssessmentUpdateTime)}
                    </Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <ReactMarkdown>{RiskAssessment || '暂无风险评估信息'}</ReactMarkdown>

                <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    AI 也会犯错，请谨慎投资
                </Typography>
            </Paper>
        </Box>
    );
};

export default TrendDataRender;
