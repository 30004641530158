import React from 'react';
import { Box, Avatar, Typography, IconButton, Menu, MenuItem, Divider, LinearProgress, ListItem, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';

function UserProfile({
                         isOpen,
                         isLoggedIn,
                         loading,
                         username,
                         handleAvatarClick,
                         anchorEl,
                         handleMenuClose,
                         handleLogout,
                         handleOpenModal,
                         displayUsername,
                     }) {
    const navigate = useNavigate();

    return (
        isOpen && (
            <Box
                sx={{
                    px: 0,
                    mx: 0,
                    background: 'linear-gradient(90deg, #f0f8ff 0%, #d6eaff 100%)',
                    clipPath: 'ellipse(100% 100% at 50% 50%)',
                    padding: '8px',
                    borderRadius: '12px',
                }}
            >
                {isLoggedIn && (
                    <Divider
                        sx={{ marginBottom: 1, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                    />
                )}
                <Box
                    sx={{
                        padding: '0 8px',
                        height: '64px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: 0,
                    }}
                >
                    {loading ? (
                        <LinearProgress
                            sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                        />
                    ) : isLoggedIn ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 1 }}> {/* 向左移动 */}
                                <Avatar
                                    onClick={handleAvatarClick}
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: '#1e88e5',
                                        marginRight: 2, // 加大与名字的间隔
                                        width: 48,
                                        height: 48,
                                        color: '#fff',
                                        fontSize: '1.2rem',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        transition: 'transform 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                >
                                    {username.charAt(0).toUpperCase()}
                                </Avatar>
                                <Typography variant="h6" sx={{ color: '#1e88e5', fontWeight: 'bold' }}>
                                    {displayUsername(username)}
                                </Typography>
                            </Box>
                            <IconButton onClick={handleAvatarClick} sx={{ color: '#1e88e5', transition: 'transform 0.3s', '&:hover': { transform: 'rotate(90deg)' } }}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                PaperProps={{
                                    style: {
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                    },
                                }}
                                sx={{
                                    mt: 1,
                                    '& .MuiPaper-root': {
                                        backgroundColor: '#fff',
                                        borderRadius: 2,
                                    },
                                }}
                            >
                                <MenuItem onClick={handleLogout}>
                                    <Typography variant="body2" sx={{ color: '#f44336' }}>登出</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => navigate('/favorites')}>
                                    <Typography variant="body2">我的收藏</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    ) : (
                        <ListItem sx={{ padding: '0 8px' }}>
                            <Button
                                onClick={handleOpenModal}
                                variant="contained"
                                color="primary"
                                fullWidth
                                startIcon={<LockIcon />}
                                sx={{
                                    margin: '8px 0',
                                    borderRadius: '16px',
                                    height: '48px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textTransform: 'none',
                                    backgroundColor: '#1e88e5',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: '#1565c0',
                                    },
                                }}
                            >
                                登录/注册
                            </Button>
                        </ListItem>
                    )}
                </Box>
            </Box>
        )
    );
}

export default UserProfile;
