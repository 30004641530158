import React, { useState, useEffect } from 'react';
import { Typography, Divider, Avatar, Box, Link, Chip, IconButton, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FaLink } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import './ResponseDisplay.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ResponseDisplay({ responses, isWaitingResponse, chatSessions = [], initialQueryResponse }) {
    const [expanded, setExpanded] = useState([]);
    const [allResponses, setAllResponses] = useState([]);
    const [showAnswer, setShowAnswer] = useState(false);

    useEffect(() => {
        const previousResponses = chatSessions.flatMap(session =>
            (session.chats || []).map(chat => ({
                userMessage: chat.title,
                text: chat.content,
                urls: Object.fromEntries((chat.urls || []).map(url => [url.title, url.url]))
            }))
        );

        const combinedResponses = [...previousResponses, ...responses];
        if (initialQueryResponse) {
            combinedResponses.unshift(initialQueryResponse);
        }

        setAllResponses(combinedResponses);
        setExpanded(new Array(combinedResponses.length).fill(false));
    }, [responses, chatSessions, initialQueryResponse]);

    useEffect(() => {
        // 如果有 response 且有 urls，就设置 showAnswer 为 true
        allResponses.forEach((response) => {
            if (response.urls && Object.keys(response.urls).length > 0) {
                setShowAnswer(true);
            }
        });
    }, [allResponses]);

    const handleExpandClick = (index) => {
        setExpanded((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    const renderTextWithCitations = (text, urls) => {
        const citationRegex = /\[(\d+)\]|【(\d+)】/g;
        return text.replace(citationRegex, (match, citationNumber1, citationNumber2) => {
            const citationNumber = citationNumber1 || citationNumber2;
            const urlEntries = Object.entries(urls || {});
            const url = urlEntries[parseInt(citationNumber) - 1] ? urlEntries[parseInt(citationNumber) - 1][1] : '#';
            return `<sup style="background-color: #d0d0d0; border-radius: 50%; padding: 2px 6px; display: inline-block; margin: 0 4px; cursor: pointer; font-size: 12px; color: black; line-height: 1; text-align: center;">
                <a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: black;">${citationNumber}</a>
            </sup>`;
        });
    };

    return (
        <>
            {allResponses.map((response, index) => (
                <div key={`${response.userMessage}-${response.timestamp}-${index}`} className="response-container">
                    {/* 问题部分 */}
                    <Box display="flex" alignItems="center">
                        {/* 呼吸灯：初始在来源左侧，答案出现后在答案左侧 */}

                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            sx={{
                                mb: 2,
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                                maxWidth: '100%',
                                overflowWrap: 'break-word',
                                hyphens: 'auto',
                                color: 'black',
                                textAlign: 'left',
                                marginBottom: '20px'
                            }}
                        >
                            {response.userMessage}
                        </Typography>
                    </Box>

                    {/* 来源部分：始终显示来源标题和图标 */}
                    <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
                        {/* 仅当呼吸灯在来源位置时显示 */}
                        {!showAnswer && index === allResponses.length - 1 && isWaitingResponse && (
                            <div className="blinking-dot" style={{ position: 'absolute', left: '-30px' }}></div>
                        )}
                        <Avatar sx={{ width: 24, height: 24, backgroundColor: '#f0f8ff' }}>
                            <FaLink style={{ color: 'black' }} />
                        </Avatar>
                        <Typography variant="body1" sx={{ ml: 1, color: 'black', textAlign: 'left' }}>来源:</Typography>

                        {/* 展开/收起按钮放在右侧 */}
                        {response.urls && Object.keys(response.urls).length > 0 && (
                            <Box ml="auto" display="flex" alignItems="center">
                                {Object.keys(response.urls).length > 5 && (
                                    <Box display="flex" alignItems="center">
                                        <Typography sx={{ color: 'black', mr: 1 }}>
                                            {expanded[index] ? "收起" : "展开"}
                                        </Typography>
                                        <Tooltip title={expanded[index] ? "收起" : "展开"} arrow>
                                            <IconButton
                                                onClick={() => handleExpandClick(index)}
                                                sx={{ color: 'black', '&:hover': { color: '#3f51b5' } }}
                                            >
                                                <ExpandMoreIcon
                                                    sx={{
                                                        transform: expanded[index] ? 'rotate(0deg)' : 'rotate(90deg)',
                                                        transition: 'transform 0.2s'
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>

                    {/* 仅在 urls 返回后显示来源的具体内容 */}
                    {response.urls && (
                        <div>
                            <Box mt={2} sx={{ ml: '24px', marginBottom: '20px' }}>
                                {Object.entries(response.urls).slice(0, expanded[index] ? Object.keys(response.urls).length : 5).map(([title, url], i) => (
                                    <Box key={i} display="flex" alignItems="center" sx={{ mb: 1 }}>
                                        <Chip
                                            label={(i + 1).toString()}
                                            size="small"
                                            sx={{ backgroundColor: 'black', color: 'white', mr: 1, borderRadius: '50%', minWidth: 32, minHeight: 24, fontSize: '0.75rem' }}
                                        />
                                        <Typography sx={{ color: 'black', textAlign: 'left' }}>
                                            <Link href={url} target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'none' }}>
                                                {title}
                                            </Link>
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </div>
                    )}

                    {/* 仅在 urls 存在且长度大于0时显示答案部分 */}
                    {response.urls && Object.keys(response.urls).length > 0 && (
                        <>
                            <Box display="flex" alignItems="center" sx={{ mb: 1, position: 'relative' }}>
                                {/* 当显示答案时，将呼吸灯移到答案左边 */}
                                {showAnswer && index === allResponses.length - 1 && isWaitingResponse && (
                                    <div className="blinking-dot" style={{ position: 'absolute', left: '-30px' }}></div>
                                )}
                                <Avatar sx={{ width: 24, height: 24, backgroundColor: '#f0f8ff' }}>
                                    <CheckCircleIcon sx={{ color: 'black' }} />
                                </Avatar>
                                <Typography variant="body1" sx={{ ml: 1, color: 'black', textAlign: 'left' }}>答案:</Typography>
                            </Box>
                            <Box display="flex" alignItems="flex-start" sx={{ mb: 2, position: 'relative' }}>
                                <div className={index === allResponses.length - 1 && isWaitingResponse ? 'with-blinking-dot' : ''}>
                                    <ReactMarkdown
                                        children={renderTextWithCitations(response.text, response.urls)}
                                        rehypePlugins={[rehypeRaw]}
                                        remarkPlugins={[remarkGfm]}
                                    />
                                </div>
                            </Box>
                        </>
                    )}

                    {index < allResponses.length - 1 &&
                        <Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.12)', margin: '20px 0' }} />}
                </div>
            ))}
        </>
    );
}

export default ResponseDisplay;
