import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FaChevronDown, FaChevronUp, FaLink, FaInfoCircle } from 'react-icons/fa';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton'; // 引入骨骼加载组件
import Tabs from '@mui/material/Tabs';  // 引入 Tabs 组件
import Tab from '@mui/material/Tab';    // 引入 Tab 组件
import './NewsPage.css';
import CalendarPicker from '../AnalysisPages/CompanyPages/CalendarPicker/CalendarPicker';
import serverAddress from "../../ServerAddress";

const NewsPage = () => {
    const [expandedNews, setExpandedNews] = useState({});
    const [activeTab, setActiveTab] = useState('A股');
    const [newsData, setNewsData] = useState({});
    const [loading, setLoading] = useState(false); // 新增的加载状态
    const [selectedDate, setSelectedDate] = useState(getDefaultDate());
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const calendarIconRef = useRef(null);

    const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds

    const saveToCache = useCallback((date, data) => {
        const cacheData = {
            data,
            timestamp: Date.now(),
        };
        localStorage.setItem(date, JSON.stringify(cacheData));
    }, []);

    const getFromCache = useCallback((date) => {
        const cacheData = localStorage.getItem(date);
        if (cacheData) {
            const parsedCache = JSON.parse(cacheData);
            const isCacheValid = Date.now() - parsedCache.timestamp < CACHE_DURATION;
            if (isCacheValid) {
                return parsedCache.data;
            }
            localStorage.removeItem(date); // Remove stale cache
        }
        return null;
    }, [CACHE_DURATION]);

    const fetchNews = useCallback(async (date) => {
        setLoading(true); // 开始加载时设置加载状态为 true
        const cachedData = getFromCache(date);
        if (cachedData) {
            setNewsData(cachedData);
            setLoading(false); // 数据加载完成后设置加载状态为 false
            return;
        }

        const formattedDate = date.replace(/-/g, ''); // Format date as YYYYMMDD

        try {
            const response = await axios.get(`${serverAddress()}/news/get_market_news`, {
                params: {
                    date: formattedDate, // Pass the formatted date
                },
            });

            const data = response.data.data; // Get data from response
            setNewsData(data);

            // Save the data to cache
            saveToCache(date, data);
        } catch (error) {
            console.error("Error fetching news data:", error);
        } finally {
            setLoading(false); // 数据加载完成或出现错误后，设置加载状态为 false
        }
    }, [getFromCache, saveToCache]);

    useEffect(() => {
        fetchNews(selectedDate);
    }, [selectedDate, fetchNews]);

    const toggleExpand = (id) => {
        setExpandedNews(prevState => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const renderSentimentIcon = (sentiment) => {
        const cleanedSentiment = sentiment.replace(/\*\*/g, ''); // Remove ** from sentiment

        if (cleanedSentiment.toLowerCase() === 'positive') {
            return <TrendingUpIcon className="sentiment-icon" style={{ color: 'red' }} />;
        } else if (cleanedSentiment.toLowerCase() === 'negative') {
            return <TrendingDownIcon className="sentiment-icon" style={{ color: 'green' }} />;
        } else if (cleanedSentiment.toLowerCase() === 'neutral') {
            return <TrendingUpIcon className="sentiment-icon" style={{ color: '#FFC107', transform: 'rotate(45deg)' }} />;
        } else {
            return null;
        }
    };

    const renderNewsItems = () => {
        if (loading) {
            return (
                <>
                    {[1, 2, 3, 4, 5].map((_, index) => (
                        <div key={index} className="news-item skeleton-loading">
                            <Skeleton variant="text" width="60%" height={40} />
                            <Skeleton variant="text" width="90%" height={20} />
                            <Skeleton variant="rectangular" width="100%" height={118} />
                        </div>
                    ))}
                </>
            );
        }

        const newsItems = Object.entries(newsData[activeTab] || {})
            .map(([category, news]) => ({ ...news, category }))
            .filter(news => news.title && news.summary && news.content)
            .sort((a, b) => new Date(b.date) - new Date(a.date));

        return newsItems.length > 0 ? (
            newsItems.map((news, index) => (
                <div
                    key={`${news.category}-${index}`}
                    className={`news-item ${expandedNews[`${news.category}-${index}`] ? 'expanded' : ''}`}
                    onClick={() => toggleExpand(`${news.category}-${index}`)}
                >
                    <div className="news-header">
                        <h2 className="news-item-title">{news.title}</h2>
                        <div className="news-meta">
                            <span className="affected-sectors">{news.affected_sectors}</span>
                            {renderSentimentIcon(news.sentiment)}
                            <span className="news-update-time">{news.date}</span>
                        </div>
                        {expandedNews[`${news.category}-${index}`] ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                    <p className="news-summary"><strong>摘要:</strong> {news.summary}</p>
                    <div className={`news-content ${expandedNews[`${news.category}-${index}`] ? 'expanded' : ''}`}>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {news.content}
                        </ReactMarkdown>
                        {news.url && (
                            <div className="news-urls-section">
                                <div className="news-url-header">
                                    <div className="icon-and-text">
                                        <FaLink />
                                        <span>相关链接</span>
                                    </div>
                                </div>
                                <div className="news-urls">
                                    <a href={news.url} target="_blank" rel="noopener noreferrer" className="news-url">
                                        {news.url}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))
        ) : (
            <div className="no-news">
                <FaInfoCircle className="no-news-icon" />
                <p>暂无相关新闻，请稍后再试。</p>
            </div>
        );
    };

    const handleDateSelect = (date) => {
        if (date) {
            setSelectedDate(date);
        }
        setShowCalendar(false);
    };

    const toggleCalendar = () => {
        if (showCalendar) {
            setShowCalendar(false);
        } else {
            const rect = calendarIconRef.current.getBoundingClientRect();
            setCalendarPosition({
                top: rect.bottom + window.scrollY + 5,
                left: rect.left + window.scrollX - 240,
            });
            setShowCalendar(true);
        }
    };

    function getDefaultDate() {
        return '2024-09-12';
    }

    const isMobileDevice = () => {
        return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);
    };

    return (
        <div className="news-container">
            <header className="news-header">
                <h1 className="news-title">AI大盘新闻</h1>
                <div className="header-content">
                    <p className="news-subtitle">获取最新的市场动态和分析</p>
                    <div className="date-picker-container">
                        <span>当前选择日期: {selectedDate}</span>
                        <IconButton
                            ref={calendarIconRef}
                            onClick={toggleCalendar}
                        >
                            <CalendarTodayIcon />
                        </IconButton>
                    </div>
                </div>
            </header>

            {showCalendar && (
                <CalendarPicker
                    onDateSelect={handleDateSelect}
                    position={calendarPosition}
                    maxDate="2024-09-12"
                    minDate="2024-08-31"
                    hideCalendar={() => setShowCalendar(false)}
                />
            )}

            {isMobileDevice() ? (
                <Tabs
                    value={activeTab}
                    onChange={(event, newValue) => setActiveTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="A股 港股 美股 切换"
                >
                    <Tab label="A股" value="A股" />
                    <Tab label="港股" value="港股" />
                    <Tab label="美股" value="美股" />
                </Tabs>
            ) : (
                <div className="tabs">
                    {['A股', '港股', '美股'].map(tab => (
                        <div
                            key={tab}
                            className={`tab ${activeTab === tab ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </div>
                    ))}
                    <div className="tab-indicator"
                         style={{ left: `${['A股', '港股', '美股'].indexOf(activeTab) * 33.33}%` }}></div>
                </div>
            )}

            <div className="news-items">
                {renderNewsItems()}
            </div>
        </div>
    );
};

export default NewsPage;
