// ChatboxVisibilityContext.jsx

import React, { createContext, useContext, useState } from 'react';

const ChatboxVisibilityContext = createContext({
    isVisible: false,
    setIsVisible: () => {},
    message: '',
    showChatbox: () => {},
    hideChatbox: () => {},
    updateMessage: () => {},  // Define updateMessage
    isSidebarOpen: true,
    toggleSidebar: () => {}
});

export function ChatboxVisibilityProvider({ children }) {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const showChatbox = (msg) => {
        setIsVisible(true);
    };

    const hideChatbox = () => {
        setIsVisible(false);
        setMessage('');
    };

    const updateMessage = (newMessage) => {  // Implement the updateMessage function
        setMessage(newMessage);
        setIsVisible(true);  // Optionally set visibility
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <ChatboxVisibilityContext.Provider value={{
            isVisible,
            setIsVisible,
            message,
            showChatbox,
            hideChatbox,
            updateMessage,  // Provide updateMessage through context
            isSidebarOpen,
            toggleSidebar
        }}>
            {children}
        </ChatboxVisibilityContext.Provider>
    );
}

export const useChatboxVisibility = () => useContext(ChatboxVisibilityContext);
