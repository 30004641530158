import React, { useEffect } from 'react';
import {
    Box,
    Paper,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // PDF 图标
import { Report } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import serverAddress from '../../../../../ServerAddress';
import ReactMarkdown from 'react-markdown';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

// 初始化 Highcharts 模块
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);

const ResearchReportSection = ({
                                   ResearchReport,
                                   ResearchReportUpdateTime,
                                   formatDate,
                                   SelectedTitles,
                                   cleanCompanyName,
                                   stockType,
                                   GraphData,
                               }) => {
    const navigate = useNavigate();

    const handlePdfClick = (title) => {
        axios
            .post(`${serverAddress()}/p/load_pdf`, {
                company_name: cleanCompanyName,
                filename: title,
                mode: 'Research',
                stocktype: stockType,
            })
            .then((response) => {
                const data = response.data;
                if (data.message === 'File loaded and model created.') {
                    navigate(`/pdf-viewer/${encodeURIComponent(title)}`, {
                        state: { companyName: cleanCompanyName, mode: 'Research', stocktype: stockType },
                    });
                } else {
                    console.error('Failed to load PDF:', data);
                }
            })
            .catch((error) => {
                console.error('There was an error loading the research PDF!', error);
            });
    };

    useEffect(() => {
        let chart;

        if (GraphData && GraphData.RevenueAndNetIncomeGrowth && GraphData.RevenueAndNetIncomeGrowth.length > 0) {
            const chartOptions = {
                exporting: {
                    enabled: false,
                },
                chart: {
                    type: 'column',
                    backgroundColor: 'transparent',
                    height: 400,
                },
                title: {
                    text: '收入与净收入增长',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#333',
                    },
                },
                xAxis: {
                    categories: GraphData.RevenueAndNetIncomeGrowth.map(data => data.Year),
                    crosshair: true,
                },
                yAxis: [{
                    title: {
                        text: '金额 (元)',
                    },
                    labels: {
                        formatter: function () {
                            return `${(this.value / 1000000000).toFixed(1)}B`;
                        },
                    },
                }],
                tooltip: {
                    shared: true,
                    valueSuffix: ' 元',
                },
                series: [
                    {
                        name: '总收入',
                        data: GraphData.RevenueAndNetIncomeGrowth.map(data => data.TotalRevenue),
                        color: '#4caf50',
                    },
                    {
                        name: '净收入',
                        data: GraphData.RevenueAndNetIncomeGrowth.map(data => data.NetIncome),
                        color: '#f44336',
                    }
                ],
                credits: {
                    enabled: false,
                },
            };

            chart = Highcharts.chart('revenue-netincome-chart', chartOptions);
        }

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [GraphData]);

    // 检查是否有数据，如果没有则不显示研究报告部分
    if (!GraphData || GraphData.RevenueAndNetIncomeGrowth.length === 0 || SelectedTitles.length === 0) {
        return null;
    }

    return (
        <Paper
            id="researchReport"
            elevation={2}
            sx={{
                p: 3,
                borderRadius: '12px',
                backgroundColor: '#f7f7f7',
                boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                mb: 4,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Report sx={{ mr: 1, color: '#f44336', fontSize: '28px' }} />
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            color: '#f44336',
                        }}
                    >
                        研究报告
                    </Typography>
                </Box>
                <Typography variant="caption" color="textSecondary">
                    更新日期：{formatDate(ResearchReportUpdateTime)}
                </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <ReactMarkdown>{ResearchReport || '暂无研究报告信息'}</ReactMarkdown>

            <Box sx={{ mt: 4 }}>
                <div id="revenue-netincome-chart" style={{ height: '400px', width: '100%' }}></div>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    来源文件
                </Typography>
                <Accordion sx={{ backgroundColor: '#f0f4f8', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#1976d2' }} />} sx={{ px: 2, py: 1 }}>
                        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', color: '#1976d2' }}>
                            点击查看来源文件 ({SelectedTitles.length} 个)
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        <List dense sx={{ maxHeight: 300, overflowY: 'auto', padding: '0 16px' }}>
                            {SelectedTitles.map((title, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': { backgroundColor: '#e3f2fd', transition: 'background-color 0.3s' },
                                        p: 1,
                                        mb: 1,
                                        borderRadius: '8px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                    onClick={() => handlePdfClick(title)}
                                >
                                    <ListItemIcon sx={{ minWidth: '40px' }}>
                                        <PictureAsPdfIcon sx={{ color: '#d32f2f', fontSize: '24px' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={title}
                                        primaryTypographyProps={{
                                            sx: { fontSize: '0.95rem', fontWeight: 'bold', color: '#333' },
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Paper>
    );
};

export default ResearchReportSection;
