import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Grid, CardContent, Button, InputAdornment, Snackbar, Alert, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { StyledCard, CompanyLogo, SearchBox, Header } from '../Style/CompanyStyle';
import serverAddress from "../../ServerAddress";
import { PuffLoader } from 'react-spinners';

const categories = [
    { name: 'A股', companies: [{ name: '贵州茅台', code: '600519' }, { name: '宁德时代', code: '300750' }, { name: '美的', code: '000333' }, { name: '比亚迪', code: '002594' }, { name: '中国平安', code: '601318' }] },
    { name: '港股', companies: [{ name: '汇丰控股', code: '0005.HK' }, { name: '美团', code: '3690.HK' }, { name: '腾讯', code: '0700.HK' }, { name: '长江和记实业', code: '0001.HK' }, { name: '新鸿基地产', code: '0016.HK' }] },
    { name: '美股', companies: [{ name: '苹果', code: 'AAPL' }, { name: '微软', code: 'MSFT' }, { name: '亚马逊', code: 'AMZN' }, { name: '英伟达', code: 'NVDA' }, { name: '耐克', code: 'NKE' }] }
];

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay !== null) {
            const id = setInterval(() => savedCallback.current(), delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

const AnalysisPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [favoriteCompanies, setFavoriteCompanies] = useState([]);
    const [prevToken, setPrevToken] = useState(localStorage.getItem('access_token'));
    const navigate = useNavigate();

    // 使用 useMediaQuery 检测是否为手机端
    const isMobile = useMediaQuery('(max-width:600px)');

    const fetchFavoriteCompanies = async () => {
        const userId = localStorage.getItem('user_id');
        const token = localStorage.getItem('access_token');

        if (!userId || !token) {
            console.error('User ID or token not found in local storage.');
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${serverAddress()}/user/get_favorites/${userId}/company`, {
                headers: {
                    'Authorization': `Bearer ${token}` // 在请求头中包含JWT token
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFavoriteCompanies(data.items);
        } catch (error) {
            console.error('Failed to fetch favorite companies:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFavoriteCompanies();
    }, []);

    useEffect(() => {
        const preloadImages = () => {
            const images = categories.flatMap(category =>
                category.companies.map(company =>
                    new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = `./CompanyImages/${company.name}.png`;
                        img.onload = resolve;
                        img.onerror = reject;
                    })
                )
            );

            Promise.all(images)
                .then(() => setImagesLoaded(true))
                .catch(err => console.error('Error loading images', err));
        };

        preloadImages();
    }, []);

    useInterval(() => {
        const token = localStorage.getItem('access_token');
        console.log('Current access_token:', token);
        if (!token) {
            setFavoriteCompanies([]);
        } else if (token !== prevToken) {
            setPrevToken(token);
            fetchFavoriteCompanies();
        }
    }, 500); // 每500毫秒检查一次 access_token

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleCompanyClick = (company, category) => {
        const companyWithCode = `${company.name} (${company.code})`;
        navigate(`/company-analysis/${encodeURIComponent(companyWithCode)}?stockType=${encodeURIComponent(category.name)}`);
    };

    const handleAddFavorite = async (company) => {
        const userId = localStorage.getItem('user_id');
        const token = localStorage.getItem('access_token');

        if (!userId || !token) {
            setSnackbarSeverity('warning');
            setSnackbarMessage('请先登录');
            setSnackbarOpen(true);
            return;
        }

        const isFavorite = favoriteCompanies.some(fav => fav.item_name === company.name && fav.stock_code === company.code);

        try {
            if (isFavorite) {
                // 取消收藏逻辑
                const response = await fetch(`${serverAddress()}/user/remove_favorite`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        item_name: company.name,
                        item_type: 'company',
                        stock_code: company.code,
                        user_id: userId
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (data.message === 'Item removed from favorites') {
                    setFavoriteCompanies(favoriteCompanies.filter(fav => !(fav.item_name === company.name && fav.stock_code === company.code)));
                    setSnackbarSeverity('success');
                    setSnackbarMessage('成功移除收藏');
                } else {
                    setSnackbarSeverity('error');
                    setSnackbarMessage('移除收藏失败');
                    console.error('Failed to remove favorite:', data);
                }
            } else {
                // 添加收藏逻辑
                const response = await fetch(`${serverAddress()}/user/add_favorite`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        item_name: company.name,
                        item_type: 'company',
                        stock_code: company.code,
                        user_id: userId
                    }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    if (response.status === 400 && errorData.detail === "Item already exists in favorites") {
                        setSnackbarSeverity('warning');
                        setSnackbarMessage('您已收藏该公司');
                    } else {
                        setSnackbarSeverity('error');
                        setSnackbarMessage('收藏失败');
                    }
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (data.message === 'Item added to favorites') {
                    setFavoriteCompanies([...favoriteCompanies, { item_name: company.name, stock_code: company.code }]);
                    setSnackbarSeverity('success');
                    setSnackbarMessage('成功收藏');
                } else {
                    setSnackbarSeverity('error');
                    setSnackbarMessage('收藏失败');
                    console.error('Failed to add favorite:', data);
                }
            }
        } catch (error) {
            console.error('Failed to modify favorite:', error);
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const filteredCompanies = categories.flatMap(category =>
        category.companies.filter(company => company.name.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    // 如果 loading 或者图片没有加载完，显示加载动画
    if (loading || !imagesLoaded) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#f0f8ff',
                    textAlign: 'center',
                    gap: 3,

                }}
            >
                <PuffLoader color="#4682b4" size={120} />
                <Typography variant="h6" sx={{ color: '#4682b4' }}>
                    正在加载公司信息，请稍候...
                </Typography>
                <Typography variant="body2" sx={{ color: '#4682b4', opacity: 0.8 }}>
                    我们正在为您加载公司列表
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                padding: 4,
                width: '100%',  // 使用百分比宽度
                maxWidth: '1200px',  // 设置最大宽度
                margin: '0 auto',
                overflowY: 'auto',  // 保持垂直滚动
                overflowX: 'hidden',  // 禁用水平滚动
                scrollbarWidth: 'none',
                marginLeft: isMobile ? '14px' : '',  // 在手机端上增加 margin-left

            }}
        >
            <Header>
                <Typography variant="h4" gutterBottom>
                    公司
                </Typography>
                <SearchBox
                    variant="outlined"
                    placeholder="搜索公司"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Header>

            {/* 搜索框不为空时展示搜索结果 */}
            {searchQuery ? (
                <Grid container spacing={2} sx={{ margin: 0, width: '100%' }}>
                    {filteredCompanies.map((company) => (
                        <Grid item xs={12} sm={6} md={4} key={company.name}>
                            <StyledCard
                                sx={{
                                    width: '100%',  // 使用百分比宽度
                                    transition: 'transform 0.3s',
                                    '&:hover': { transform: 'scale(1.05)' }
                                }}
                                onClick={() => handleCompanyClick(company, { name: '分类名' })}
                            >
                                <CompanyLogo>
                                    <img src={`./CompanyImages/${company.name}.png`} alt={company.name} />
                                </CompanyLogo>
                                <CardContent>
                                    <Typography variant="body1" gutterBottom>
                                        {company.name} ({company.code})
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        startIcon={favoriteCompanies.some(fav => fav.item_name === company.name && fav.stock_code === company.code) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                        onClick={(e) => { e.stopPropagation(); handleAddFavorite(company); }}
                                    >
                                        {favoriteCompanies.some(fav => fav.item_name === company.name && fav.stock_code === company.code) ? '已收藏' : '收藏'}
                                    </Button>
                                </CardContent>
                            </StyledCard>
                        </Grid>
                    ))}

                    {/* 当搜索结果不足三项时，添加占位符 */}
                    {filteredCompanies.length < 3 &&
                        Array.from(new Array(3 - filteredCompanies.length)).map((_, index) => (
                            <Grid item xs={12} sm={6} md={4} key={`placeholder-${index}`}>
                                <StyledCard
                                    sx={{
                                        width: '100%',  // 使用百分比宽度
                                        visibility: 'hidden', // 隐藏占位符卡片，但占位
                                    }}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            ) : (
                // 搜索框为空时按分类展示公司
                categories.map((category) => (
                    <Box key={category.name} sx={{ marginBottom: 4, width: '100%' }}>
                        <Typography variant="h5" gutterBottom>
                            {category.name}
                        </Typography>
                        <Grid container spacing={2} sx={{ margin: 0, width: '100%' }}>
                            {category.companies.map((company) => (
                                <Grid item xs={12} sm={6} md={4} key={company.name}>
                                    <StyledCard
                                        sx={{
                                            width: '100%',  // 使用百分比宽度
                                            transition: 'transform 0.3s',
                                            '&:hover': { transform: 'scale(1.05)' }
                                        }}
                                        onClick={() => handleCompanyClick(company, category)}
                                    >
                                        <CompanyLogo>
                                            <img src={`./CompanyImages/${company.name}.png`} alt={company.name} />
                                        </CompanyLogo>
                                        <CardContent>
                                            <Typography variant="body1" gutterBottom>
                                                {company.name} ({company.code})
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                startIcon={favoriteCompanies.some(fav => fav.item_name === company.name && fav.stock_code === company.code) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                                onClick={(e) => { e.stopPropagation(); handleAddFavorite(company); }}
                                            >
                                                {favoriteCompanies.some(fav => fav.item_name === company.name && fav.stock_code === company.code) ? '已收藏' : '收藏'}
                                            </Button>
                                        </CardContent>
                                    </StyledCard>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );

};

export default AnalysisPage;
