import axios from 'axios';
import serverAddress from "../../../ServerAddress";

const token = localStorage.getItem('access_token');

const CACHE_EXPIRATION_TIME = 3 * 60 * 60 * 1000; // 3 hours

const isCacheValid = (timestamp) => {
    const currentTime = new Date().getTime();
    return (currentTime - timestamp) < CACHE_EXPIRATION_TIME;
};

export const extractCompanyInfo = (name) => {
    const match = name.match(/(.+?)\s*\((.*?)\)/);
    if (match) {
        return { companyName: match[1], stockCode: match[2] };
    }
    return { companyName: name, stockCode: '' };
};

export const fetchFavoriteCompanies = async () => {
    const userId = localStorage.getItem('user_id');
    if (!userId) {
        throw new Error('User ID not found in local storage.');
    }

    const response = await fetch(`${serverAddress()}/user/get_favorites/${userId}/company`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.items;
};

export const handleAddFavorite = async (company, favoriteCompanies, setFavoriteCompanies, setSnackbarSeverity, setSnackbarMessage, setSnackbarOpen) => {
    const userId = localStorage.getItem('user_id');
    const token = localStorage.getItem('access_token');

    if (!userId || !token) {
        setSnackbarSeverity('warning');
        setSnackbarMessage('请先登录');
        setSnackbarOpen(true);
        return;
    }

    const isFavorite = favoriteCompanies.some(fav => fav.item_name === company.companyName && fav.stock_code === company.stockCode);

    try {
        if (isFavorite) {
            const response = await fetch(`${serverAddress()}/user/remove_favorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    item_name: company.companyName,
                    item_type: 'company',
                    user_id: userId
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.message === 'Item removed from favorites') {
                setFavoriteCompanies(favoriteCompanies.filter(fav => !(fav.item_name === company.companyName && fav.stock_code === company.stockCode)));
                setSnackbarSeverity('success');
                setSnackbarMessage('成功移除收藏');
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage('移除收藏失败');
                throw new Error('Failed to remove favorite');
            }
        } else {
            const response = await fetch(`${serverAddress()}/user/add_favorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    item_name: company.companyName,
                    item_type: 'company',
                    stock_code: company.stockCode,
                    user_id: userId
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (response.status === 400 && errorData.detail === "Item already exists in favorites") {
                    setSnackbarSeverity('warning');
                    setSnackbarMessage('您已收藏该公司');
                } else {
                    setSnackbarSeverity('error');
                    setSnackbarMessage('收藏失败');
                }
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.message === 'Item added to favorites') {
                setFavoriteCompanies([...favoriteCompanies, { item_name: company.companyName, stock_code: company.stockCode }]);
                setSnackbarSeverity('success');
                setSnackbarMessage('成功收藏');
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage('收藏失败');
                throw new Error('Failed to add favorite');
            }
        }
    } catch (error) {
        console.error('Failed to modify favorite:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage('操作失败');
    } finally {
        setSnackbarOpen(true);
    }
};
export const fetchDataByView = async (view, companyName, date = null, stockType = '') => {
    let url;

    if (view === '新闻') {
        url = `${serverAddress()}/news/get_company_news?date=${date}&company_name=${encodeURIComponent(companyName)}`;
    } else if (view === '财报' || view === '研报') {
        url = `${serverAddress()}/c/get_details?stock_type=${encodeURIComponent(stockType)}&company_name=${encodeURIComponent(companyName)}&report_type=${view === '财报' ? 'Financial' : 'Research'}&filename=details.json`;
    }

    const cacheKey = `${view}-${companyName}-${date || ''}-${stockType}`;
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    const cachedTimestamp = parseInt(localStorage.getItem(`${cacheKey}-timestamp`), 10);

    if (cachedData && cachedTimestamp && isCacheValid(cachedTimestamp)) {
        return cachedData.map(item => ({
            ...item,
            title: item.title.replace(/\.md$/, '')  // 去掉 .md 后缀
        }));
    }

    try {
        const response = await axios.get(url);
        const data = response.data;

        // 检查响应是否包含 file_url
        if (data.success && data.file_url) {
            const s3Response = await axios.get(data.file_url);
            const fileData = s3Response.data;

            // 去掉 .md 后缀
            const cleanedData = fileData.map(item => ({
                ...item,
                title: item.title.replace(/\.md$/, '')  // 去掉 .md 后缀
            }));

            const currentTime = new Date().getTime();
            localStorage.setItem(cacheKey, JSON.stringify(cleanedData));
            localStorage.setItem(`${cacheKey}-timestamp`, currentTime.toString());

            return cleanedData;
        } else {
            const cleanedData = data.map(item => ({
                ...item,
                title: item.title.replace(/\.md$/, '')  // 去掉 .md 后缀
            }));

            const currentTime = new Date().getTime();
            localStorage.setItem(cacheKey, JSON.stringify(cleanedData));
            localStorage.setItem(`${cacheKey}-timestamp`, currentTime.toString());

            return cleanedData;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};



export const handleResearchClick = (report, companyName, stockType, navigate, setPdfLoading) => {
    const { companyName: cleanCompanyName } = extractCompanyInfo(companyName);

    setPdfLoading(true); // Start loading spinner

    axios.post(`${serverAddress()}/p/load_pdf`, {
        company_name: cleanCompanyName,
        filename: report.title,
        mode: 'Research',
        stocktype: stockType
    })
        .then(response => {
            const data = response.data;
            if (data.message === 'File loaded and model created.') {
                navigate(`/pdf-viewer/${encodeURIComponent(report.title)}`, { state: { companyName: cleanCompanyName, mode: 'Research', stocktype: stockType } });
            } else {
                console.error('Failed to load PDF:', data);
            }
        })
        .catch(error => {
            console.error("There was an error loading the research PDF!", error);
        })
        .finally(() => {
            setPdfLoading(false); // Stop loading spinner
        });
};

export const handleTrendClick = async (companyName, stockType, setData, setLoading, testMode = true) => {
    setLoading(true);
    try {
        // 构建URL，加入 test 参数
        const testParam = testMode ? '&test=false' : '';
        const response = await axios.get(`${serverAddress()}/c/get_trend?stock_type=${encodeURIComponent(stockType)}&company_name=${encodeURIComponent(companyName)}${testParam}`);
        const trendData = response.data;
        setData(trendData);
    } catch (error) {
        console.error('Error fetching trend data:', error);
    } finally {
        setLoading(false);
    }
};


export const handleReportClick = (report, companyName, stockType, navigate, setPdfLoading) => {
    const { companyName: cleanCompanyName } = extractCompanyInfo(companyName);

    setPdfLoading(true); // Start loading spinner

    axios.post(`${serverAddress()}/p/load_pdf`, {
        company_name: cleanCompanyName,
        filename: report.title,
        mode: 'Financial',
        stocktype: stockType
    })
        .then(response => {
            const data = response.data;
            if (data.message === 'File loaded and model created.') {
                navigate(`/pdf-viewer/${encodeURIComponent(report.title)}`, { state: { companyName: cleanCompanyName, mode: 'Financial', stocktype: stockType } });
            } else {
                console.error('Failed to load PDF:', data);
            }
        })
        .catch(error => {
            console.error("There was an error loading the PDF!", error);
        })
        .finally(() => {
            setPdfLoading(false); // Stop loading spinner
        });
};
