import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBook, faNetworkWired, faGlobe, faNewspaper } from '@fortawesome/free-solid-svg-icons';

export const searchModes = {
    '普通模式': { mode: 'normal', icon: faBook, color: 'blue', description: '不搜索网络直接生成聊天' },
    '学术搜索': { mode: 'scholar', icon: faNetworkWired, color: 'green', description: '搜素已发表的学术文章' },
    '全网搜索': { mode: 'net', icon: faGlobe, color: 'orange', description: '整个互联网搜索' },
    '新闻搜索': { mode: 'news', icon: faNewspaper, color: 'red', description: '专注新闻的搜索' }
};

const SearchModeSelector = ({ searchMode, handleSearchModeChange }) => {
    return (
        <div className="dropdown search-mode-dropdown">
            <button
                className="btn btn-secondary dropdown-toggle d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ paddingTop: '6px', paddingBottom: '6px' }}
            >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: '8px', alignSelf: 'center' }} />
                <span style={{ alignSelf: 'center' }}>{searchMode}</span>
            </button>

            <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuButton" style={{ width: '400px' }}>
                <div className="row">
                    {Object.keys(searchModes).map((mode) => (
                        <div className="col-6 mb-2" key={mode}>
                            <button className="dropdown-item d-flex align-items-start"
                                    onClick={() => handleSearchModeChange(mode)}
                                    style={{ whiteSpace: 'normal', width: '100%' }}>
                                <FontAwesomeIcon icon={searchModes[mode].icon}
                                                 style={{ color: searchModes[mode].color, marginRight: '8px', marginTop: '3px' }} />
                                <div>
                                    <div>{mode}</div>
                                    <small className="text-muted">{searchModes[mode].description}</small>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export { SearchModeSelector };
