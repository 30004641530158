import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Tabs, Tab, Box, Typography, InputAdornment } from '@mui/material';
import { AccountCircle, PersonAdd, Lock } from '@mui/icons-material';
import axios from 'axios';
import serverAddress from "../../../ServerAddress";

function LoginRegisterModal({
                                open,
                                onClose,
                                username,
                                setUsername,
                                password,
                                setPassword,
                                confirmPassword,
                                setConfirmPassword,
                                error,
                                setError,
                                success,
                                setSuccess,
                                setIsLoggedIn
                            }) {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (open) {
            setActiveTab(0);
            setError('');
            setSuccess('');
        }
    }, [open, setError, setSuccess]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setError('');
        setSuccess('');
    };

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            setError('密码和确认密码不一致');
            return;
        }

        if (username.includes(' ')) {
            setError('用户名不能包含空格');
            return;
        }

        if (password.includes(' ')) {
            setError('密码不能包含空格');
            return;
        }

        try {
            await axios.post(`${serverAddress()}/auth/register_user`, {
                username,
                password
            });

            setSuccess('注册成功，请登录！');
            setError('');
        } catch (err) {
            const errorMessage = err.response?.data?.error || err.response?.data?.detail || '注册失败';
            if (errorMessage === '400: Username already exists') {
                setError('用户名已存在');
            } else {
                setError(errorMessage);
            }
        }
    };

    const handleLogin = async () => {
        if (!username || !password) {
            setError('用户名和密码不能为空');
            return;
        }

        try {
            const response = await axios.post(`${serverAddress()}/auth/verify_user`, {
                username,
                password
            }, {
                withCredentials: true
            });

            if (response.data.access_token) {
                setSuccess('用户登录成功！');
                setError('');
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('username', username);
                setIsLoggedIn(true);

                try {
                    const userIdResponse = await axios.get(`${serverAddress()}/recent/get_user_id`, {
                        params: { username },
                        headers: {
                            Authorization: `Bearer ${response.data.access_token}`
                        }
                    });

                    if (userIdResponse.data.user_id) {
                        localStorage.setItem('user_id', userIdResponse.data.user_id);
                    } else {
                        setError('获取用户ID失败');
                    }
                } catch (err) {
                    setError('获取用户ID失败');
                }

                onClose();
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error;
            if (errorMessage === 'Invalid username or password') {
                setError('用户名或密码无效');
            } else {
                setError(errorMessage || '登录失败');
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                        p: 2,
                        background: 'linear-gradient(135deg, #f0f8ff, #e6f3ff)', // 更柔和的渐变
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)', // 柔和的阴影
                    }
                }}>
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem', color: '#1e88e5' }}>
                欢迎加入内测
            </DialogTitle>
            <DialogContent>
                <Tabs value={activeTab} onChange={handleTabChange} centered>
                    <Tab icon={<AccountCircle />} label="登录" sx={{ fontWeight: 'bold', color: '#1e88e5' }} />
                    <Tab icon={<PersonAdd />} label="注册" sx={{ fontWeight: 'bold', color: '#1e88e5' }} />
                </Tabs>
                {activeTab === 0 && (
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="用户名"
                            type="text"
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="dense"
                            label="密码"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {error && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                    color: '#f44336',
                                    borderRadius: 2,
                                    p: 1,
                                    mt: 2,
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                }}
                            >
                                <Box sx={{ mr: 1 }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        width="24"
                                        height="24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </Box>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    {error}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
                {activeTab === 1 && (
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="用户名"
                            type="text"
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="dense"
                            label="密码"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="dense"
                            label="确认密码"
                            type="password"
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {error && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                    color: '#f44336',
                                    borderRadius: 2,
                                    p: 1,
                                    mt: 2,
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                }}
                            >
                                <Box sx={{ mr: 1 }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        width="24"
                                        height="24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </Box>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    {error}
                                </Typography>
                            </Box>
                        )}
                        {success && (
                            <Typography color="primary" variant="body2" sx={{ mt: 1 }}>
                                {success}
                            </Typography>
                        )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
                <Button onClick={onClose} sx={{
                    background: 'linear-gradient(135deg, #f0f8ff, #d6eaff)', // 更浅的蓝色调
                    color: '#000',
                    px: 3,
                    borderRadius: 2,
                    '&:hover': {
                        background: 'linear-gradient(135deg, #d0e6ff, #b0d6ff)', // 浅色悬停效果
                    }
                }}>
                    取消
                </Button>
                <Button
                    onClick={activeTab === 0 ? handleLogin : handleRegister}
                    sx={{
                        background: 'linear-gradient(135deg, #d0e6ff, #b0d6ff)', // 按钮的渐变色
                        color: '#000',
                        px: 3,
                        borderRadius: 2,
                        '&:hover': {
                            background: 'linear-gradient(135deg, #b0d6ff, #90c6ff)', // 浅蓝色悬停效果
                        }
                    }}
                >
                    {activeTab === 0 ? '登录' : '注册'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LoginRegisterModal;
