import { styled } from '@mui/system';
import { Box, Card, TextField } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '10px',
    background: 'linear-gradient(145deg, #f5f7fa, #e2e6ea)',
    marginBottom: theme.spacing(1), // 减少底部间距
    margin: theme.spacing(1), // 增加左右间距使其更紧凑
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
        transform: 'scale(1.05)', // 使用 scale 使卡片放大
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
    },
    border: '1px solid #e0e0e0',
    width: '100%',
    maxWidth: '280px', // 确保最大宽度
    flexGrow: 1, // 确保卡片在可用空间内均匀分布
}));


export const CompanyLogo = styled(Box)(({ theme }) => ({
    width: 120,
    height: 120,
    backgroundColor: '#f5f5f5',
    marginBottom: theme.spacing(1),
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        borderRadius: '5px',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '25px', // 圆角输入框
        backgroundColor: '#f7f7f7', // 浅色背景
        boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
        '&.Mui-focused': {
            boxShadow: '0px 4px 10px rgba(0,0,0,0.2)', // 聚焦时加深阴影
        },
    },
}));

export const SearchBox = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2), // 设置下方间距
    width: '100%', // 设置宽度为 100%
    [theme.breakpoints.up('sm')]: {
        width: '300px', // 在屏幕尺寸较大时，宽度调整为 300px
    },
    '& .MuiOutlinedInput-root': { // 定制输入框的根节点样式
        borderRadius: '25px', // 设置输入框的圆角半径为 25px
        backgroundColor: '#f7f7f7', // 设置输入框的背景颜色为浅灰色
        boxShadow: '0px 2px 5px rgba(0,0,0,0.1)', // 设置输入框的阴影
        '&.Mui-focused': { // 当输入框聚焦时的样式
            boxShadow: '0px 4px 10px rgba(0,0,0,0.2)', // 聚焦时阴影加深
        },
    },
}));

export const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '2px solid #e0e0e0',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(4),
    '& .logo': {
        width: '250px', // 设置 logo 的宽度为 250px
        height: 'auto', // 高度自适应，保持图像的比例
        display: 'flex',
        justifyContent: 'center',
    },
}));

export const CompanyInfo = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});
