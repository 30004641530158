import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ReactMarkdown from 'react-markdown';

const RenderItem = ({ item, displayName, selectedView }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getSentimentIcon = () => {
        const sentiment = item.sentiment || item.label;

        if (sentiment) {
            if (sentiment.toLowerCase() === 'positive' || sentiment.toLowerCase() === '**positive**') {
                return <TrendingUpIcon style={{ color: 'lightcoral', fontSize: '1.5rem', marginRight: '10px' }} />;
            } else if (sentiment.toLowerCase() === 'negative' || sentiment.toLowerCase() === '**negative**') {
                return <TrendingDownIcon style={{ color: 'lightgreen', fontSize: '1.5rem', marginRight: '10px' }} />;
            } else if (sentiment.toLowerCase() === 'neutral' || sentiment.toLowerCase() === '**neutral**') {
                return <TrendingUpIcon style={{ color: '#FFC107', fontSize: '1.5rem', marginRight: '10px', transform: 'rotate(42deg)' }} />;
            }
        }

        return null;
    };

    return (
        <Box
            key={item.id}
            sx={{
                marginBottom: 2,
                border: '1px solid #ddd',
                borderRadius: '8px',  // 圆角
                padding: '16px',  // 增加内边距
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',  // 添加阴影
                cursor: 'pointer',
                transition: 'all 0.3s ease',  // 平滑过渡效果
                '&:hover': {
                    backgroundColor: '#f5f5f5',  // hover时背景变化
                    transform: 'translateY(-2px)',  // 触摸时轻微提升
                },
                '&:active': {
                    backgroundColor: '#e0e0e0'  // 点击反馈
                }
            }}
            onClick={handleExpandClick}
        >

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{item.title}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                        {item.source !== '未知来源' && (
                            <>
                                <Typography variant="body2" sx={{ color: '#888' }}>{item.source}</Typography>
                                <Typography variant="body2" sx={{ margin: '0 4px', fontSize: '1.5rem' }}>·</Typography>
                            </>
                        )}
                        <Typography variant="body2" sx={{ color: '#888' }}>{item.date}</Typography>
                    </Box>
                </Box>
                {selectedView === '新闻' && getSentimentIcon()}
            </Box>

            {expanded ? (
                <Box>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                        <strong>摘要：</strong>
                    </Typography>
                    <ReactMarkdown>{item.summary}</ReactMarkdown>
                    <ReactMarkdown>{item.content}</ReactMarkdown>
                </Box>
            ) : (
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    <strong>摘要：</strong>
                    <Typography
                        variant="body2"
                        noWrap
                        sx={{
                            maxHeight: '4.8em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical'
                        }}
                    >
                        <ReactMarkdown>{item.summary}</ReactMarkdown>
                    </Typography>
                </Typography>
            )}

            {expanded && (
                <Typography variant="body2" sx={{ color: 'gray', textAlign: 'left', marginBottom: 2 }}>
                    AI也会犯错，不构成任何投资建议
                </Typography>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        marginRight: '8px',
                        border: '1px solid #ddd',  // 图片边框
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'  // 轻微阴影
                    }}>
                        <img src={`/CompanyImages/${displayName}.png`} alt={displayName} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Box>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{displayName}</Typography>
                </Box>

                {selectedView === '新闻' && (
                    <IconButton onClick={handleExpandClick}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

export default RenderItem;
