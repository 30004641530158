import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import serverAddress from "../../ServerAddress";

const useBooks = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [favoriteBooks, setFavoriteBooks] = useState([]);
    const [prevToken, setPrevToken] = useState(localStorage.getItem('access_token'));
    const navigate = useNavigate();

    const fetchFavoriteBooks = async () => {
        const userId = localStorage.getItem('user_id');
        const token = localStorage.getItem('access_token');



        try {
            const response = await fetch(`${serverAddress()}/user/get_favorites/${userId}/book`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFavoriteBooks(data.items.map(item => item.item_name));
        } catch (error) {
            console.error('Failed to fetch favorite books:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFavoriteBooks();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const token = localStorage.getItem('access_token');
            if (!token) {
                setFavoriteBooks([]);
            } else if (token !== prevToken) {
                setPrevToken(token);
                fetchFavoriteBooks();
            }
        }, 500);

        return () => clearInterval(intervalId);
    }, [prevToken]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleBookClick = async (book) => {
        setLoading(true);

        try {
            const response = await fetch(`${serverAddress()}/p/load_pdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ filename: book }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.message === 'File loaded and model created.') {
                navigate(`/pdf-viewer/${encodeURIComponent(book)}`);
            } else {
                console.error('Failed to load PDF:', data);
            }
        } catch (error) {
            console.error('Failed to load PDF:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddFavorite = async (book) => {
        const userId = localStorage.getItem('user_id');
        const token = localStorage.getItem('access_token');

        if (!userId || !token) {
            setSnackbarSeverity('warning');
            setSnackbarMessage('请先登录');
            setSnackbarOpen(true);
            return;
        }

        try {
            if (favoriteBooks.includes(book)) {
                const response = await fetch(`${serverAddress()}/user/remove_favorite`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        item_name: book,
                        item_type: 'book',
                        user_id: userId
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (data.message === 'Item removed from favorites') {
                    setFavoriteBooks(favoriteBooks.filter(item => item !== book));
                    setSnackbarSeverity('success');
                    setSnackbarMessage('成功移除收藏');
                } else {
                    setSnackbarSeverity('error');
                    setSnackbarMessage('移除收藏失败');
                    console.error('Failed to remove favorite:', data);
                }
            } else {
                const response = await fetch(`${serverAddress()}/user/add_favorite`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        item_name: book,
                        item_type: 'book',
                        user_id: userId
                    }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    if (response.status === 400 && errorData.detail === "Item already exists in favorites") {
                        setSnackbarSeverity('warning');
                        setSnackbarMessage('您已收藏该书籍');
                    } else {
                        setSnackbarSeverity('error');
                        setSnackbarMessage('收藏失败');
                    }
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (data.message === 'Item added to favorites') {
                    setFavoriteBooks([...favoriteBooks, book]);
                    setSnackbarSeverity('success');
                    setSnackbarMessage('成功收藏');
                } else {
                    setSnackbarSeverity('error');
                    setSnackbarMessage('收藏失败');
                    console.error('Failed to add favorite:', data);
                }
            }
        } catch (error) {
            console.error('Failed to modify favorite:', error);
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return {
        searchQuery,
        setSearchQuery,
        loading,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        favoriteBooks,
        handleSearchChange,
        handleBookClick,
        handleAddFavorite,
        handleCloseSnackbar
    };
};

export default useBooks;
