import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const RenderFinancialReport = ({ item, displayName, onReportClick }) => {
    return (
        <Box
            key={item.id}
            sx={{
                marginBottom: 2,
                border: '1px solid #ddd',
                borderRadius: '8px',  // 添加圆角
                padding: '16px',  // 增加内边距
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // 添加阴影
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: '#f5f5f5', // 更柔和的 hover 颜色
                    transform: 'translateY(-2px)', // hover 时轻微提升卡片
                    transition: 'all 0.3s ease', // 添加过渡效果
                },
                '&:active': {
                    backgroundColor: '#e0e0e0',  // 点击时的背景色
                    transition: 'background-color 0.2s'
                }
            }}
            onClick={() => onReportClick(item)}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{item.title}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                        {item.source !== '未知来源' && (
                            <Typography variant="body2" sx={{ color: '#888' }}>{item.source}</Typography>
                        )}
                        <Typography variant="body2" sx={{ marginLeft: item.source !== '未知来源' ? '4px' : '0', color: '#888' }}>2024-07-05</Typography>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="body1">
                    <strong>摘要：</strong>
                </Typography>
                <ReactMarkdown
                    components={{
                        p: ({ children }) => (
                            <Typography
                                variant="body2"
                                noWrap
                                sx={{
                                    maxHeight: '4.8em',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical'
                                }}
                            >
                                {children}
                            </Typography>
                        )
                    }}
                >
                    {item.summary}
                </ReactMarkdown>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
                <ReactMarkdown>
                    {item.content}
                </ReactMarkdown>
            </Box>

            <Typography variant="body2" sx={{ color: 'gray', textAlign: 'left', marginBottom: 2 }}>
                AI也会犯错，不构成任何投资建议
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        marginRight: '8px',
                        border: '1px solid #ddd', // 添加边框
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' // 轻微阴影
                    }}>
                        <img src={`/CompanyImages/${displayName}.png`} alt={displayName} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Box>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{displayName}</Typography> {/* 加粗的部分 */}
                </Box>
            </Box>
        </Box>
    );
};

export default RenderFinancialReport;
