import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ResearchReportIcon from '@mui/icons-material/Assessment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

const ViewToggleButtonGroup = ({ selectedView, handleViewChange }) => {
    return (
        <ToggleButtonGroup
            value={selectedView}
            exclusive
            onChange={handleViewChange}
            aria-label="view selection"
            sx={{
                marginTop: '-50px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                borderRadius: '12px',
                backgroundColor: '#f0f8ff',
                padding: '10px',
                '& .MuiToggleButton-root': {
                    margin: '0 8px',
                    borderRadius: '8px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #e0e0e0',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        backgroundColor: '#CCEFFF',
                        color: '#002B5C',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#0088CC',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        boxShadow: '0 4px 12px rgba(0, 136, 204, 0.3)',
                    },
                    fontSize: '1rem', // 默认字体大小
                },
                // 针对移动设备的样式调整
                '@media (max-width: 600px)': {
                    '& .MuiToggleButton-root': {
                        fontSize: '0.7rem', // 缩小字体
                        padding: '5px', // 减小内边距
                        margin: '0 4px', // 缩小间距
                        '& .MuiSvgIcon-root': {
                            fontSize: '1rem', // 图标变小
                            marginRight: '4px', // 图标与文字的间距
                        },
                    },
                },
            }}
        >
            <ToggleButton value="财报" aria-label="财报">
                <BarChartIcon sx={{ marginRight: '8px' }} />
                财报
            </ToggleButton>
            <ToggleButton value="新闻" aria-label="新闻">
                <NewspaperIcon sx={{ marginRight: '8px' }} />
                新闻
            </ToggleButton>
            <ToggleButton value="研报" aria-label="研报">
                <ResearchReportIcon sx={{ marginRight: '8px' }} />
                研报
            </ToggleButton>
            <ToggleButton value="趋势" aria-label="趋势">
                <FontAwesomeIcon icon={faChartLine} style={{ marginRight: '8px' }} />
                趋势
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default ViewToggleButtonGroup;
