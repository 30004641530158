import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {Typography, Box, Button, Snackbar, Alert, IconButton, Pagination, useMediaQuery} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ResearchReportIcon from '@mui/icons-material/Assessment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { PuffLoader } from 'react-spinners'; // 引入 PuffLoader
import { Header, CompanyInfo } from '../../Style/CompanyStyle';
import { extractCompanyInfo, fetchFavoriteCompanies, handleAddFavorite, fetchDataByView, handleReportClick, handleResearchClick, handleTrendClick } from './companyUtils';
import RenderItem from './NewsRenderItem/RenderItem';
import RenderFinancialReport from './FinancialReportRenderItem/RenderFinancialReport';
import RenderResearchItem from './ResearchRenderItem/RenderResearchItem';
import './CompanyPage.css';
import CalendarPicker from './CalendarPicker/CalendarPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import TrendDataRender from './TrendDataRender/TrendDataRender';
import ViewToggleButtonGroup from './ViewToggleButtonGroup'; // 引入新组件

const CACHE_EXPIRATION_TIME = 30 * 60 * 1000; // 30 minutes

const CompanyPage = () => {
    const { companyName } = useParams();
    const navigate = useNavigate();
    const [selectedView, setSelectedView] = useState('新闻'); // Default to '新闻'
    const isMobile = useMediaQuery('(max-width:600px)');

    // 分别管理不同视图的数据和加载状态
    const [newsData, setNewsData] = useState([]);
    const [financialReportData, setFinancialReportData] = useState([]);
    const [researchData, setResearchData] = useState([]);
    const [trendData, setTrendData] = useState([]);

    const [newsLoading, setNewsLoading] = useState(false);
    const [financialReportLoading, setFinancialReportLoading] = useState(false);
    const [researchLoading, setResearchLoading] = useState(false);
    const [trendLoading, setTrendLoading] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [favoriteCompanies, setFavoriteCompanies] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const [selectedDate, setSelectedDate] = useState(getDefaultDate());
    const [pdfLoading, setPdfLoading] = useState(false);
    const [cleanCompanyName, setCleanCompanyName] = useState('');
    const [stockType, setStockType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const calendarIconRef = useRef(null);

    const handleViewChange = (event, newView) => {
        if (newView !== null) {
            setSelectedView(newView);
        }
    };

    const handleDateSelect = (date) => {
        if (date) {
            setSelectedDate(date);
        }
    };

    function getDefaultDate() {
        return "2024-09-12"; // 固定返回 9月12号
    }

    const isCacheValid = (timestamp) => {
        const currentTime = new Date().getTime();
        return (currentTime - timestamp) < CACHE_EXPIRATION_TIME;
    };

    const fetchNews = useCallback(async (company, date) => {
        setNewsLoading(true);
        setNewsData([]); // 清空新闻数据
        const formattedDate = date.replace(/-/g, '');

        try {
            const cacheKey = `newsData-${company}-${date}`;
            const cachedData = JSON.parse(localStorage.getItem(cacheKey));
            const cachedTimestamp = parseInt(localStorage.getItem(`${cacheKey}-timestamp`), 10);

            if (cachedData && cachedTimestamp && isCacheValid(cachedTimestamp)) {
                setNewsData(cachedData);
                setNewsLoading(false);
                return;
            }

            const data = await fetchDataByView('新闻', company, formattedDate);
            const currentTime = new Date().getTime();

            localStorage.setItem(cacheKey, JSON.stringify(data));
            localStorage.setItem(`${cacheKey}-timestamp`, currentTime.toString());
            setNewsData(data);
        } catch (error) {
            console.error("Error fetching news data:", error);
        } finally {
            setNewsLoading(false);
        }
    }, []);

    const fetchTrendData = useCallback(async (company, stockType) => {
        setTrendLoading(true); // 开始加载趋势数据时将 trendLoading 设置为 true
        setTrendData([]); // 清空趋势数据

        try {
            const cacheKey = `trendData-${company}`;
            const cachedData = JSON.parse(localStorage.getItem(cacheKey));
            const cachedTimestamp = parseInt(localStorage.getItem(`${cacheKey}-timestamp`), 10);

            if (cachedData && cachedTimestamp && isCacheValid(cachedTimestamp)) {
                setTrendData(cachedData);
                setTrendLoading(false); // 加载完成时设置 trendLoading 为 false
                return;
            }

            await handleTrendClick(company, stockType, (trendData) => {
                if (trendData && trendData.trend_data) {
                    setTrendData(trendData.trend_data);

                    const currentTime = new Date().getTime();
                    localStorage.setItem(cacheKey, JSON.stringify(trendData.trend_data));
                    localStorage.setItem(`${cacheKey}-timestamp`, currentTime.toString());
                }
            }, setTrendLoading);
        } catch (error) {
            console.error('Error fetching trend data:', error);
        } finally {
            setTrendLoading(false); // 确保无论成功与否都将 trendLoading 设置为 false
        }
    }, []);

    const fetchFinancialReports = useCallback(async (company, date, stockType) => {
        setFinancialReportLoading(true);
        setFinancialReportData([]); // 清空财报数据

        try {
            const data = await fetchDataByView('财报', company, date, stockType);
            setFinancialReportData(data);
        } catch (error) {
            console.error('Failed to fetch financial reports:', error);
        } finally {
            setFinancialReportLoading(false);
        }
    }, []);

    const fetchResearchReports = useCallback(async (company, date, stockType) => {
        setResearchLoading(true);
        setResearchData([]); // 清空研报数据

        try {
            const data = await fetchDataByView('研报', company, date, stockType);
            setResearchData(data);
        } catch (error) {
            console.error('Failed to fetch research reports:', error);
        } finally {
            setResearchLoading(false);
        }
    }, []);

    useEffect(() => {
        const loadFavorites = async () => {
            try {
                const favorites = await fetchFavoriteCompanies();
                setFavoriteCompanies(favorites);
            } catch (error) {
                console.error('Failed to fetch favorite companies:', error);
            }
        };

        loadFavorites();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const { companyName: extractedCompanyName, stockCode } = extractCompanyInfo(companyName);
            const determinedStockType = determineStockType(stockCode);

            setCleanCompanyName(extractedCompanyName);
            setStockType(determinedStockType);

            const apiDate = selectedDate.replace(/-/g, '');
            if (selectedView === '新闻') {
                await fetchNews(extractedCompanyName, selectedDate);
            } else if (selectedView === '趋势') {
                await fetchTrendData(extractedCompanyName, determinedStockType);
            } else if (selectedView === '财报') {
                await fetchFinancialReports(extractedCompanyName, apiDate, determinedStockType);
            } else if (selectedView === '研报') {
                await fetchResearchReports(extractedCompanyName, apiDate, determinedStockType);
            }
        };

        fetchData();
    }, [selectedView, selectedDate, companyName, fetchNews, fetchTrendData, fetchFinancialReports, fetchResearchReports]);

    const determineStockType = (stockCode) => {
        if (stockCode.endsWith('.HK')) {
            return 'stockHk';
        } else if (stockCode.match(/^\d{6}$/)) {
            return 'stockA';
        } else {
            return 'stockUs';
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleReportItemClick = (item) => {
        const { companyName: cleanCompanyName, stockCode } = extractCompanyInfo(companyName);
        const stockType = determineStockType(stockCode);
        handleReportClick(item, cleanCompanyName, stockType, navigate, setPdfLoading);
    };

    const handleResearchItemClick = (item) => {
        const { companyName: cleanCompanyName, stockCode } = extractCompanyInfo(companyName);
        const stockType = determineStockType(stockCode);
        handleResearchClick(item, cleanCompanyName, stockType, navigate, setPdfLoading);
    };

    const { companyName: displayName, stockCode } = extractCompanyInfo(companyName);

    const isFavorite = favoriteCompanies.some(fav => fav.item_name === displayName && fav.stock_code === stockCode);

    const totalPages = selectedView === '新闻' ? Math.ceil(newsData.length / itemsPerPage) :
        selectedView === '财报' ? Math.ceil(financialReportData.length / itemsPerPage) :
            selectedView === '研报' ? Math.ceil(researchData.length / itemsPerPage) :
                selectedView === '趋势' && trendData ? 1 : 0;

    const currentItems = selectedView === '新闻' ? newsData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) :
        selectedView === '财报' ? financialReportData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) :
            selectedView === '研报' ? researchData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) :
                trendData ? trendData : [];

    return (
        <div className="page-container" style={{ backgroundColor: '#f0f8ff', position: 'relative', minHeight: '100vh' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: 4, width: '100%', maxWidth: '1200px', margin: '0 auto', height: '100vh', boxSizing: 'border-box' }}>
                <Header sx={{ marginLeft: '-7.8px', marginRight: "8px", marginTop: '11px' }}>
                    <CompanyInfo>
                        <Typography variant="h4" sx={{ marginBottom: '20px', color: '#000', fontWeight: 'bold' }}>
                            {displayName} ({stockCode})
                        </Typography>

                        <Button
                            variant="outlined"
                            startIcon={isFavorite ? <FavoriteIcon className="favorite-icon" /> : <FavoriteBorderIcon className="favorite-icon" />}
                            className="favorite-button"
                            onClick={async (e) => {
                                e.stopPropagation();
                                await handleAddFavorite({ companyName: displayName, stockCode }, favoriteCompanies, setFavoriteCompanies, setSnackbarSeverity, setSnackbarMessage, setSnackbarOpen);
                            }}
                        >
                            {isFavorite ? '已收藏' : '收藏'}
                        </Button>

                    </CompanyInfo>
                </Header>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <ViewToggleButtonGroup selectedView={selectedView} handleViewChange={handleViewChange} />



                    <Box sx={{ display: 'flex', alignItems: 'center', minHeight: '48px' }}>
                        {selectedView === '新闻' && (
                            <>
                                <Typography
                                    sx={{
                                        marginRight: 2,
                                        fontSize: { xs: '0.5em', sm: '1em' },
                                        color: '#004080'
                                    }}
                                >
                                    {selectedDate ? `当前选择日期: ${selectedDate}` : '未选择日期'}
                                </Typography>
                                <IconButton
                                    ref={calendarIconRef}
                                    onClick={() => {
                                        const rect = calendarIconRef.current.getBoundingClientRect();
                                        setCalendarPosition({
                                            top: rect.bottom + window.scrollY + 5,
                                            left: rect.left + window.scrollX - 200,
                                        });
                                        setShowCalendar(!showCalendar);
                                    }}
                                    sx={{ color: '#000000' }} // 修改为黑色
                                >
                                    <CalendarTodayIcon />
                                </IconButton>

                            </>
                        )}
                    </Box>
                </Box>

                {showCalendar && selectedView === '新闻' && (
                    <CalendarPicker
                        onDateSelect={handleDateSelect}
                        position={calendarPosition}
                        maxDate="2024-09-12"
                        defaultDate="2024-09-12"
                        hideCalendar={() => setShowCalendar(false)}
                    />
                )}

                {(newsLoading || financialReportLoading || researchLoading || trendLoading) ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',

                            alignItems: 'center',
                            height: '100vh',
                            backgroundColor: '#f0f8ff', // 背景颜色与之前的设置保持一致
                            textAlign: 'center',
                            gap: 3, // 控制文字与动画之间的间距
                        }}
                    >
                        {/* PuffLoader 动画大小变大，颜色为搭配的颜色 */}
                        <PuffLoader color="#4682b4" size={120} />
                        {/* 添加加载提示文字 */}
                        <Typography variant="h6" sx={{ color: '#4682b4' }}>
                            正在加载，请稍候...
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#4682b4', opacity: 0.8 }}>
                            我们正在为您准备数据
                        </Typography>
                    </Box>
                ) : (
                    <Box className="content-container" sx={{ overflowY: 'auto', transition: 'all 0.3s ease-in-out' }}>
                        {selectedView === '趋势' && trendData && !Array.isArray(trendData) ? (
                            <TrendDataRender trendData={trendData} stockType={stockType} cleanCompanyName={cleanCompanyName} />
                        ) : currentItems.length === 0 ? (
                            <Box sx={{
                                textAlign: 'center',
                                marginTop: 4,
                                padding: 4,
                                borderRadius: 2,
                                backgroundColor: '#dbecfa',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '200px',
                                animation: 'fadeIn 0.3s ease-in-out',
                                color: '#004080'
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                                    {selectedView === '新闻' && (
                                        <>
                                            <NewspaperIcon sx={{ fontSize: 60, color: '#888888' }} />
                                            <Typography variant="h6" color="textSecondary" sx={{ mt: 1 }}>
                                                暂无新闻内容，请稍后再试。
                                            </Typography>
                                        </>
                                    )}
                                    {selectedView === '财报' && (
                                        <>
                                            <BarChartIcon sx={{ fontSize: 60, color: '#888888' }} />
                                            <Typography variant="h6" color="textSecondary" sx={{ mt: 1 }}>
                                                暂无财报内容，请稍后再试。
                                            </Typography>
                                        </>
                                    )}
                                    {selectedView === '研报' && (
                                        <>
                                            <ResearchReportIcon sx={{ fontSize: 60, color: '#888888' }} />
                                            <Typography variant="h6" color="textSecondary" sx={{ mt: 1 }}>
                                                暂无研报内容，请稍后再试。
                                            </Typography>
                                        </>
                                    )}
                                    {selectedView === '趋势' && (
                                        <>
                                            <FontAwesomeIcon icon={faChartLine} style={{ fontSize: 60, color: '#888888' }} />
                                            <Typography variant="h6" color="textSecondary" sx={{ mt: 1 }}>
                                                暂无趋势内容，请稍后再试。
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                                <Typography variant="body2" color="textSecondary">
                                    请稍后再试或更换其他选项。
                                </Typography>
                            </Box>
                        ) : (
                            currentItems.map(item => (
                                selectedView === '财报' ? (
                                    <RenderFinancialReport key={item.id} item={item} displayName={displayName} onReportClick={handleReportItemClick} />
                                ) : selectedView === '研报' ? (
                                    <RenderResearchItem key={item.id} item={item} displayName={displayName} onReportClick={handleResearchItemClick} />
                                ) : (
                                    <RenderItem key={item.id} item={item} displayName={displayName} selectedView={selectedView} />
                                )
                            ))
                        )}

                        {totalPages > 1 && (
                            <Box
                                sx={{
                                    position: 'fixed', // 固定在屏幕上
                                    bottom: 0, // 始终在底部
                                    left: 0,
                                    width: '100%',
                                    backgroundColor: '#f0f8ff',
                                    zIndex: 999,
                                    padding: '10px 0',
                                    display: 'flex',
                                    justifyContent: 'center',

                                    ...(isMobile && {
                                        bottom: '-900px',  // 在移动设备上，固定到底部
                                        marginLeft: '0px',  // 去掉marginLeft偏移
                                    }),
                                }}
                            >
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(event, value) => setCurrentPage(value)}
                                    color="primary"
                                    showFirstButton
                                    showLastButton
                                    sx={{
                                        '& .Mui-selected': { backgroundColor: '#0088CC', color: '#fff' },
                                        '& .MuiPaginationItem-root': {
                                            minWidth: isMobile ? 28 : 'auto', // 根据设备类型调整最小宽度
                                            height: isMobile ? 28 : 'auto',   // 根据设备类型调整高度
                                            fontSize: isMobile ? '0.75em' : '1em', // 根据设备类型调整字体大小
                                            padding: isMobile ? 0 : '6px 12px', // 根据设备类型调整内边距
                                        },
                                        '& .MuiPagination-ul': {
                                            gap: isMobile ? '4px' : '8px', // 根据设备类型调整分页按钮间距
                                        },
                                    }}
                                />

                            </Box>

                        )}
                    </Box>
                )}

                {pdfLoading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 9999 }}>
                        <PuffLoader color="#4682b4" size={60} />
                    </Box>
                )}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </div>
    );
};

export default CompanyPage;
