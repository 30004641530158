import React, { useEffect } from 'react';
import { Paper, Box, Typography, Divider } from '@mui/material';
import { Assessment } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';

// Initialize Highcharts modules
HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const CompanySection = ({ CompanyProfile, CompanyProfileUpdateTime, formatDate, GraphData }) => {
    // Output the chart data to the console
    console.log('GraphData:', GraphData);
    console.log("receive date",CompanyProfileUpdateTime)
    useEffect(() => {
        if (GraphData && GraphData.AnalystRatings) {
            // Configure chart options with updated range and aesthetics
            const chartOptions = {
                chart: {
                    type: 'solidgauge',
                    height: '200px', // Reduced height for a smaller chart
                    backgroundColor: 'transparent',
                    styledMode: false, // Allows for custom styling via options
                },
                title: {
                    text: '分析师推荐评级',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#333',
                        textAlign: 'center',
                    },
                },
                tooltip: {
                    enabled: false, // Disable tooltip for a cleaner look
                },
                pane: {
                    startAngle: -150,
                    endAngle: 150,
                    background: [{
                        backgroundColor: '#f0f0f0',
                        borderWidth: 0,
                        outerRadius: '100%',
                        innerRadius: '80%',
                        shape: 'arc'
                    }]
                },
                yAxis: {
                    min: 0,
                    max: 3, // Updated max value
                    stops: [
                        [0.33, '#55BF3B'], // Green up to 1
                        [0.66, '#DDDF0D'], // Yellow from 1 to 2
                        [1.0, '#DF5353']   // Red from 2 to 3
                    ],
                    lineWidth: 0,
                    tickWidth: 0,
                    minorTickInterval: null,
                    tickAmount: 0,
                    title: {
                        text: '评级',
                        y: -40,
                        style: {
                            fontSize: '14px',
                            color: '#333'
                        }
                    },
                    labels: {
                        enabled: false
                    }
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            y: 0,
                            borderWidth: 0,
                            useHTML: true,
                            format: '<div style="text-align:center"><span style="font-size:24px;color: #333">{y}</span><br/><span style="font-size:12px;color: #666">评级</span></div>'
                        },
                        animation: {
                            duration: 1500
                        },
                        rounded: true, // Rounded edges for a smoother look
                    }
                },
                series: [{
                    name: '评级',
                    data: [GraphData.AnalystRatings.RecommendationMean], // Ensure this value is between 0 and 3
                    tooltip: {
                        valueSuffix: ' 评级'
                    },
                    dataLabels: {
                        format: '<div style="text-align:center"><span style="font-size:24px;color: #333">{y}</span><br/><span style="font-size:12px;color: #666">评级</span></div>'
                    },
                    color: {
                        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                        stops: [
                            [0, '#4CAF50'],
                            [1, '#81C784']
                        ]
                    }
                }],
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            pane: {
                                background: [{
                                    backgroundColor: '#f0f0f0',
                                    borderWidth: 0,
                                    outerRadius: '100%',
                                    innerRadius: '80%',
                                    shape: 'arc'
                                }]
                            },
                            yAxis: {
                                title: {
                                    style: {
                                        fontSize: '12px'
                                    }
                                }
                            },
                            plotOptions: {
                                solidgauge: {
                                    dataLabels: {
                                        format: '<div style="text-align:center"><span style="font-size:18px;color: #333">{y}</span><br/><span style="font-size:10px;color: #666">评级</span></div>'
                                    }
                                }
                            }
                        }
                    }]
                }
            };

            // Render the chart
            Highcharts.chart('analyst-recommendation-chart', chartOptions);
        }
    }, [GraphData]);

    return (
        <Paper
            id="companyProfile"
            elevation={2}
            sx={{
                p: 3,
                borderRadius: '12px',
                backgroundColor: '#f7f7f7',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                mb: 4,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Assessment sx={{ mr: 1, color: '#1976d2', fontSize: '28px' }} />
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            color: '#1976d2',
                        }}
                    >
                        公司概况
                    </Typography>
                </Box>
                {/* 使用CompanyProfileUpdateTime */}
                <Typography variant="caption" color="textSecondary">
                    更新日期：{formatDate(CompanyProfileUpdateTime)}
                </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <ReactMarkdown>{CompanyProfile || '暂无公司概况信息'}</ReactMarkdown>

            {/* Enhanced and smaller chart */}
            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <div id="analyst-recommendation-chart" style={{ height: '200px', width: '100%' }}></div>
            </Box>
        </Paper>
    );
};

export default CompanySection;
