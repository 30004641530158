import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useChatboxVisibility } from '../../contexts/ChatboxVisibilityContext';
import './SearchPage.css';
import Footer from './Footer/Footer';
import Contact from './Footer/Contact/Contact.jsx';
import { v4 as uuidv4 } from 'uuid';
import { GiMoneyStack, GiArtificialIntelligence, GiNetworkBars, GiSolarPower, GiHealthNormal, GiRobotGolem } from 'react-icons/gi';
import { searchModes, SearchModeSelector } from './SearchModeSelector/SearchModeSelector';  // Ensure this import is correct

const previewQuestions = require('./previewQuestions.json');

const categoryIcons = {
    '宏观经济与政策': <GiMoneyStack style={{ color: 'blue', marginRight: '8px' }} />,
    '科技创新与投资机会': <GiArtificialIntelligence style={{ color: 'green', marginRight: '8px' }} />,
    '金融科技与数字化': <GiNetworkBars style={{ color: 'purple', marginRight: '8px' }} />,
    '能源与环境经济': <GiSolarPower style={{ color: 'orange', marginRight: '8px' }} />,
    '生物科技与医疗投资': <GiHealthNormal style={{ color: 'red', marginRight: '8px' }} />,
    '智能制造与工业革命': <GiRobotGolem style={{ color: 'gray', marginRight: '8px' }} />
};

function getRandomQuestions(categories, count) {
    const selectedQuestions = [];
    const categoryKeys = Object.keys(categories);
    const shuffledCategories = categoryKeys.sort(() => 0.5 - Math.random());

    for (let i = 0; i < count; i++) {
        const category = shuffledCategories[i % shuffledCategories.length];
        const questions = categories[category];
        const questionIndex = Math.floor(Math.random() * questions.length);
        selectedQuestions.push({ question: questions[questionIndex], category });
    }

    return selectedQuestions;
}

function SearchPage() {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchMode, setSearchMode] = useState('全网搜索');
    const [randomQuestions, setRandomQuestions] = useState([]);
    const navigate = useNavigate();
    const { showChatbox } = useChatboxVisibility();
    const textareaRef = useRef(null);
    const minHeight = 150;
    const maxHeight = 300;

    useEffect(() => {
        setRandomQuestions(getRandomQuestions(previewQuestions, 6));
    }, []);

    useEffect(() => {
        localStorage.setItem('mode', 'net');
        setSearchMode('全网搜索');
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        adjustTextareaHeight();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && searchQuery.trim() !== '') {
            event.preventDefault();
            executeSearch();
        }
    };

    const handleButtonClick = () => {
        if (searchQuery.trim() !== '') {
            executeSearch();
        }
    };

    const executeSearch = () => {
        const chatId = uuidv4();
        showChatbox(searchQuery);
        navigate(`/chat/${chatId}`, { state: { initialQuery: searchQuery, searchMode: searchModes[searchMode].mode } });
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            const newHeight = Math.min(Math.max(textareaRef.current.scrollHeight, minHeight), maxHeight);
            textareaRef.current.style.height = `${newHeight}px`;
        }
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [searchQuery]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = `${minHeight}px`;
        }
    }, []);

    const handlePreviewQuestionClick = (question) => {
        setSearchQuery(question);
        showChatbox(question);
        const chatId = uuidv4();
        navigate(`/chat/${chatId}`, { state: { initialQuery: question, searchMode: searchModes[searchMode].mode } });
    };

    const handleSearchModeChange = (mode) => {
        setSearchMode(mode);
        console.log(`Search mode changed to: ${searchModes[mode].mode}`);
        localStorage.setItem('mode', searchModes[mode].mode);
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="container mt-5 flex-grow-1">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center position-relative">
                        <h4 className="mb-3 h4">AI智投搜索引擎</h4>
                        <div className="textarea-container">
                            <textarea
                                ref={textareaRef}
                                className="form-control textarea"
                                placeholder="尽管问..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onKeyPress={handleKeyPress}
                            />
                            <div className="textarea-actions">
                                <SearchModeSelector
                                    searchMode={searchMode}
                                    handleSearchModeChange={handleSearchModeChange}
                                />
                                <button
                                    className="search-button"
                                    onClick={handleButtonClick}
                                    disabled={searchQuery.trim() === ''}
                                >
                                    ➔
                                </button>
                            </div>
                        </div>

                        <div className="preview-questions">
                            {randomQuestions.map(({ question, category }, index) => (
                                <div
                                    key={index}
                                    className="preview-question"
                                    onClick={() => handlePreviewQuestionClick(question)}
                                >
                                    {categoryIcons[category]}
                                    {question}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Routes>
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </div>
    );
}

export default SearchPage;
