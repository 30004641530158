import React, { useState, useRef, useEffect } from 'react';
import { TextField, Box, Avatar, Typography } from '@mui/material';
import { useChatboxVisibility } from '../../../contexts/ChatboxVisibilityContext';
import { useLocation, useParams } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ResponseDisplay from './ResponseDisplay/ResponseDisplay';
import useChatbox from './useChatbox';
import './Chatbox.css';

function Chatbox({ isSidebarOpen }) {
    const [chatMessage, setChatMessage] = useState('');
    const [chatSessionsReceived, setChatSessionsReceived] = useState(false);
    const { isVisible, message } = useChatboxVisibility();
    const currentLocation = useLocation();
    const { chatid } = useParams();
    const { responses, isWaitingResponse, handleSendMessage, setIsFirstMessage } = useChatbox(currentLocation, chatid);
    const [initialQueryResponse, setInitialQueryResponse] = useState(null);
    const [isInitialQueryHandled, setIsInitialQueryHandled] = useState(false);

    const messageEndRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        if (currentLocation.state && currentLocation.state.chatSessions) {
            setChatSessionsReceived(true);
            setIsFirstMessage(false);
        }
        if (currentLocation.state && currentLocation.state.initialQuery) {
            setInitialQueryResponse({
                userMessage: currentLocation.state.initialQuery,
                text: '',
                urls: {}
            });
            setIsInitialQueryHandled(true);
        }
    }, [currentLocation, setIsFirstMessage]);

    useEffect(() => {
        if (isInitialQueryHandled && responses.length > 0) {
            setInitialQueryResponse(null);
            setIsInitialQueryHandled(false);
        }
    }, [responses, isInitialQueryHandled]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const inputContainerStyle = {
        left: isMobile
            ? `calc((100% - ${isSidebarOpen ? '35%' : 'calc(100% - 36%)'}) / 2)`
            : `calc((100% - ${isSidebarOpen ? '17%' : 'calc(100% - 63%)'}) / 2)`,
        width: isMobile
            ? (isSidebarOpen ? '65%' : `80%`)
            : (isSidebarOpen ? '30%' : `40%`),
        position: 'fixed',
        bottom: '0',
        transition: 'left 0.3s ease, width 0.3s ease'
    };

    useEffect(() => {
        if (isVisible) {
            messageEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'end', inline: 'nearest' });
        }
    }, [responses, isVisible]);

    if (!isVisible && !chatSessionsReceived) return null;

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !isWaitingResponse) {
            e.preventDefault();
            if (chatMessage.trim()) {
                const messageToSend = chatMessage;
                setChatMessage('');
                await handleSendMessage(messageToSend);
            }
        }
    };

    return (
        <div className="chatboxContainer">
            <div className="messageContainer" style={{ overflowY: 'auto', maxHeight: 'calc(80vh - 100px)' }}>
                <Typography variant="body1">{message}</Typography>
                <ResponseDisplay
                    responses={responses}
                    isWaitingResponse={isWaitingResponse}
                    chatSessions={currentLocation.state?.chatSessions || []}
                    initialQueryResponse={initialQueryResponse}
                />
                <div style={{ paddingBottom: '50px' }} ref={messageEndRef} />
            </div>
            <div className="inputContainer" style={inputContainerStyle}>
                <TextField
                    fullWidth
                    value={chatMessage}
                    onChange={(e) => setChatMessage(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    multiline
                    maxRows={4}
                    onKeyPress={handleKeyPress}
                    className="customTextField"
                    sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '25px',
                    }}
                    InputProps={{
                        endAdornment: (
                            <Box position="absolute" right="25px" bottom="15px" transform="translateY(-50%)" zIndex="1">
                                <Avatar sx={{ width: 24, height: 24, bgcolor: isWaitingResponse ? 'black' : chatMessage.trim() ? 'black' : 'lightgray' }}>
                                    {isWaitingResponse ? <HourglassEmptyIcon /> : <ArrowForwardIcon />}
                                </Avatar>
                            </Box>
                        ),
                        style: {
                            borderRadius: '25px'
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default Chatbox;
