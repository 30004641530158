import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

const GrowthTrendChartHighcharts = () => {
    useEffect(() => {
        // 收入、利润、股息增长趋势图
        Highcharts.chart('highcharts-growth-container', {
            title: {
                text: '利润、收入、股息增长趋势'
            },
            xAxis: {
                categories: ['2018', '2019', '2020', '2021', '2022', '2023']
            },
            yAxis: {
                title: {
                    text: '金额 (百万美元)'
                }
            },
            series: [
                {
                    name: '收入',
                    data: [500, 600, 700, 850, 900, 1100]
                },
                {
                    name: '利润',
                    data: [50, 70, 90, 110, 150, 180]
                },
                {
                    name: '股息',
                    data: [20, 25, 30, 35, 40, 45],
                    type: 'column'
                }
            ],
            tooltip: {
                shared: true,
                valueSuffix: ' 百万美元'
            },
            credits: {
                enabled: false
            }
        });

        // 历史股价趋势图
        Highcharts.chart('highcharts-stock-price-container', {
            title: {
                text: '历史股价趋势'
            },
            xAxis: {
                categories: ['2018', '2019', '2020', '2021', '2022', '2023']
            },
            yAxis: {
                title: {
                    text: '股价 (美元)'
                }
            },
            series: [
                {
                    name: '股价',
                    data: [120, 150, 180, 210, 250, 300], // 历史股价数据
                    type: 'line'
                }
            ],
            tooltip: {
                shared: true,
                valueSuffix: ' 美元'
            },
            credits: {
                enabled: false
            }
        });

        // 机构持股比例图
        Highcharts.chart('highcharts-institutional-holders-container', {
            title: {
                text: '机构持股比例'
            },
            xAxis: {
                categories: ['2018', '2019', '2020', '2021', '2022', '2023']
            },
            yAxis: {
                title: {
                    text: '持股比例 (%)'
                }
            },
            series: [
                {
                    name: '机构持股比例',
                    data: [65, 67, 68, 70, 72, 74], // 机构持股比例数据
                    type: 'area'
                }
            ],
            tooltip: {
                shared: true,
                valueSuffix: ' %'
            },
            credits: {
                enabled: false
            }
        });

        // 推荐评级图 - 扇形图表示单个季度的评级数据
        Highcharts.chart('highcharts-recommendations-container', {
            chart: {
                type: 'pie'
            },
            title: {
                text: '分析师推荐评级'
            },
            series: [
                {
                    name: '推荐评级',
                    data: [
                        { name: '买入', y: 40 }, // 假设买入评级为40%
                        { name: '持有', y: 35 }, // 假设持有评级为35%
                        { name: '卖出', y: 25 }  // 假设卖出评级为25%
                    ]
                }
            ],
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            credits: {
                enabled: false
            }
        });

        // 环境、社会与治理（ESG）图表
        Highcharts.chart('highcharts-esg-container', {
            chart: {
                type: 'column'
            },
            title: {
                text: '环境、社会与治理（ESG）评分趋势'
            },
            xAxis: {
                categories: ['2018', '2019', '2020', '2021', '2022', '2023']
            },
            yAxis: {
                min: 0,
                title: {
                    text: '得分 (满分100)'
                }
            },
            series: [
                {
                    name: '环境得分',
                    data: [78, 80, 82, 85, 87, 88]
                },
                {
                    name: '社会得分',
                    data: [70, 72, 74, 77, 78, 80]
                },
                {
                    name: '治理得分',
                    data: [65, 68, 70, 72, 75, 78]
                }
            ],
            tooltip: {
                shared: true,
                valueSuffix: ' 分'
            },
            credits: {
                enabled: false
            }
        });
    }, []);

    return (
        <>
            <div id="highcharts-growth-container" style={{ height: '500px' }}></div>
            <div id="highcharts-stock-price-container" style={{ height: '500px', marginTop: '50px' }}></div>
            <div id="highcharts-institutional-holders-container" style={{ height: '500px', marginTop: '50px' }}></div>
            <div id="highcharts-recommendations-container" style={{ height: '500px', marginTop: '50px' }}></div>
            <div id="highcharts-esg-container" style={{ height: '500px', marginTop: '50px' }}></div>
        </>
    );
};

export default GrowthTrendChartHighcharts;
