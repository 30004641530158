import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, CardContent, Button, InputAdornment, Snackbar, Alert, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { StyledCard, BookCover, SearchBox, Header } from '../Style/BookStyle'; // 确保路径正确
import useBooks from './useBooks'; // 引入自定义hook
import { PuffLoader } from 'react-spinners'; // 引入 PuffLoader

const categories = [
    { name: '投资', books: ['彼得林奇的成功投资', '滚雪球-巴菲特和他的财富人生', '投资最重要的事', '投资者的未来', '安全边际'] },
    { name: '经济金融', books: ['国富论', '金融炼金术', '黑天鹅', '股市真规则', '漫步华尔街'] },
    { name: '理财', books: ['百万富翁快车道', '富爸爸穷爸爸', '小狗钱钱', '富爸爸投资指南', '邻家的百万富翁'] }
];

function LearnPage() {
    const {
        searchQuery,
        loading,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        favoriteBooks,
        handleSearchChange,
        handleBookClick,
        handleAddFavorite,
        handleCloseSnackbar
    } = useBooks();

    const [imagesLoaded, setImagesLoaded] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    // 根据搜索内容过滤书籍
    const filteredBooks = categories.flatMap(category =>
        category.books.filter(book => book.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    // 模拟图片加载
    useEffect(() => {
        const preloadImages = () => {
            const images = filteredBooks.map(book =>
                new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = `./BookImages/${book}.jpg`;
                    img.onload = resolve;
                    img.onerror = reject;
                })
            );

            Promise.all(images)
                .then(() => setImagesLoaded(true))
                .catch(err => console.error('Error loading images', err));
        };

        preloadImages();
    }, [filteredBooks]);

    // 如果loading或图片还没有加载完毕，显示自定义loading动画
    if (loading || !imagesLoaded) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#f0f8ff',
                    textAlign: 'center',
                    gap: 3,
                }}
            >
                <PuffLoader color="#4682b4" size={120} />
                <Typography variant="h6" sx={{ color: '#4682b4' }}>
                    正在加载，请稍候...
                </Typography>
                <Typography variant="body2" sx={{ color: '#4682b4', opacity: 0.8 }}>
                    我们正在为您准备书籍信息
                </Typography>
            </Box>
        );
    }

    // 主页面内容
    return (
        <Box
            sx={{
                padding: 4,
                maxWidth: '1200px',
                margin: '0 auto',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                ...(isMobile && { marginLeft: '12px' }),
            }}
        >
            <Header>
                <Typography variant="h4" gutterBottom>
                    书籍
                </Typography>
                <SearchBox
                    variant="outlined"
                    placeholder="搜索书籍"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Header>

            {/* 当有搜索内容时，直接显示搜索结果，不分类 */}
            {searchQuery ? (
                <Grid container spacing={2}>
                    {filteredBooks.map((book) => (
                        <Grid item xs={12} sm={6} md={4} key={book}>
                            <StyledCard
                                sx={{
                                    minWidth: '280px',
                                    maxWidth: '320px',
                                    transition: 'transform 0.3s',
                                    '&:hover': { transform: 'scale(1.05)' }
                                }}
                                onClick={() => handleBookClick(book)}
                            >
                                <BookCover>
                                    <img src={`./BookImages/${book}.jpg`} alt={book} />
                                </BookCover>
                                <CardContent>
                                    <Typography variant="body1" gutterBottom>
                                        {book}
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        startIcon={favoriteBooks.includes(book) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                        onClick={(e) => { e.stopPropagation(); handleAddFavorite(book); }}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: favoriteBooks.includes(book) ? 'rgba(253, 216, 53, 0.12)' : 'rgba(0, 0, 0, 0.04)', // 收藏前后的背景色
                                                borderColor: favoriteBooks.includes(book) ? '#fdd835' : 'rgba(0, 0, 0, 0.12)', // 收藏前后的边框色
                                                color: favoriteBooks.includes(book) ? '#fdd835' : 'inherit', // 收藏前后的文本色
                                            },
                                            borderColor: favoriteBooks.includes(book) ? '#fdd835' : 'rgba(0, 0, 0, 0.12)', // 默认边框颜色
                                            color: favoriteBooks.includes(book) ? '#fdd835' : 'inherit', // 默认文本颜色
                                        }}
                                    >
                                        {favoriteBooks.includes(book) ? '已收藏' : '收藏'}
                                    </Button>

                                </CardContent>
                            </StyledCard>
                        </Grid>
                    ))}

                    {/* 当书籍不足三本时，添加占位符 */}
                    {filteredBooks.length < 3 &&
                        Array.from(new Array(3 - filteredBooks.length)).map((_, index) => (
                            <Grid item xs={12} sm={6} md={4} key={`placeholder-${index}`}>
                                <StyledCard
                                    sx={{
                                        minWidth: '400px',
                                        maxWidth: '400px',
                                        visibility: 'hidden', // 隐藏占位符卡片，但占位
                                    }}
                                />
                            </Grid>
                        ))}
                </Grid>
            ) : (
                // 无搜索内容时，按照分类展示
                categories.map((category) => (
                    <Box key={category.name} sx={{ marginBottom: 4, width: '100%' }}>
                        <Typography variant="h5" gutterBottom>
                            {category.name}
                        </Typography>
                        <Grid container spacing={2}>
                            {category.books.map((book) => (
                                <Grid item xs={12} sm={6} md={4} key={book}>
                                    <StyledCard
                                        sx={{
                                            minWidth: '280px',
                                            maxWidth: '320px',
                                            transition: 'transform 0.3s',
                                            '&:hover': { transform: 'scale(1.05)' }
                                        }}
                                        onClick={() => handleBookClick(book)}
                                    >
                                        <BookCover>
                                            <img src={`./BookImages/${book}.jpg`} alt={book} />
                                        </BookCover>
                                        <CardContent>
                                            <Typography variant="body1" gutterBottom>
                                                {book}
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                startIcon={favoriteBooks.includes(book) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                                onClick={(e) => { e.stopPropagation(); handleAddFavorite(book); }}
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.08)', // 轻微改变背景色
                                                        borderColor: favoriteBooks.includes(book) ? '#2828ed' : 'rgba(0, 0, 0, 0.12)', // 改变边框颜色
                                                        color: favoriteBooks.includes(book) ? '#0c0c9f' : 'inherit', // 收藏时文本颜色变化
                                                    },
                                                }}
                                            >
                                                {favoriteBooks.includes(book) ? '已收藏' : '收藏'}
                                            </Button>
                                        </CardContent>
                                    </StyledCard>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default LearnPage;
