// src/components/TrendDataRender/MarketSection/MarketSection.jsx

import React, { useEffect } from 'react';
import { Paper, Box, Typography, Divider } from '@mui/material';
import { TrendingUp } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

// 初始化 Highcharts 模块（不包括 responsive）
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);

const MarketSection = ({ MarketPerformance, MarketPerformanceUpdateTime, formatDate, GraphData }) => {
    // 输出图表数据到控制台（可选）
    console.log('GraphData:', GraphData);

    useEffect(() => {
        let chart; // 用于存储图表实例

        if (GraphData && GraphData.StockPerformance) {
            const { Dates, Close } = GraphData.StockPerformance;

            // 将日期字符串转换为时间戳
            const processedDates = Dates.map(dateStr => new Date(dateStr).getTime());

            // 配置 Highcharts 选项
            const chartOptions = {
                chart: {
                    type: 'spline', // 使用 spline 类型，使线条更平滑
                    height: 400,
                    backgroundColor: 'transparent',
                    styledMode: false,
                    zoomType: 'x', // 允许在X轴上缩放
                },
                title: {
                    text: '股票表现走势图',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#333',
                        textAlign: 'center',
                    },
                },
                subtitle: {
                    text: `截至：${formatDate(MarketPerformanceUpdateTime)}`,
                    style: {
                        fontSize: '14px',
                        color: '#666',
                        textAlign: 'center',
                    },
                },
                xAxis: {
                    type: 'datetime',
                    title: {
                        text: '日期',
                        style: {
                            fontSize: '14px',
                            color: '#333',
                        },
                    },
                    labels: {
                        format: '{value:%Y-%m-%d}',
                        style: {
                            fontSize: '12px',
                            color: '#666',
                        },
                        rotation: -45, // 旋转标签以避免重叠
                        align: 'right',
                    },
                    gridLineColor: '#e0e0e0',
                },
                yAxis: {
                    title: {
                        text: '收盘价 (元)',
                        style: {
                            fontSize: '14px',
                            color: '#333',
                        },
                    },
                    labels: {
                        style: {
                            fontSize: '12px',
                            color: '#666',
                        },
                    },
                    gridLineColor: '#e0e0e0',
                },
                tooltip: {
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormat: '{point.x:%Y-%m-%d}: {point.y} 元',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    borderColor: '#ddd',
                    borderRadius: 5,
                    style: {
                        color: '#333',
                        fontSize: '12px',
                    },
                },
                legend: {
                    enabled: false, // 隐藏图例
                },
                plotOptions: {
                    spline: {
                        marker: {
                            enabled: true,
                            radius: 3,
                            symbol: 'circle',
                            fillColor: '#ff4081',
                            lineColor: '#fff',
                            lineWidth: 1,
                        },
                        lineWidth: 2,
                        states: {
                            hover: {
                                lineWidth: 3,
                            },
                        },
                        animation: {
                            duration: 1000,
                        },
                    },
                },
                series: [
                    {
                        name: '收盘价',
                        data: processedDates.map((time, index) => [time, Close[index]]),
                        color: '#ff4081',
                        tooltip: {
                            valueSuffix: ' 元',
                        },
                        shadow: {
                            color: 'rgba(0, 0, 0, 0.1)',
                            width: 2,
                            offsetX: 0,
                            offsetY: 2,
                        },
                    },
                ],
                credits: {
                    enabled: false,
                },
                exporting: {
                    enabled: false,
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 600
                        },
                        chartOptions: {
                            chart: {
                                height: 300,
                            },
                            title: {
                                style: {
                                    fontSize: '16px',
                                },
                            },
                            subtitle: {
                                style: {
                                    fontSize: '12px',
                                },
                            },
                            xAxis: {
                                labels: {
                                    format: '{value:%Y-%m-%d}',
                                    style: {
                                        fontSize: '10px',
                                    },
                                    rotation: -45,
                                    align: 'right',
                                },
                            },
                            yAxis: {
                                title: {
                                    text: '收盘价 (元)',
                                    style: {
                                        fontSize: '12px',
                                    },
                                },
                                labels: {
                                    style: {
                                        fontSize: '10px',
                                    },
                                },
                            },
                            tooltip: {
                                style: {
                                    fontSize: '10px',
                                },
                            },
                            plotOptions: {
                                spline: {
                                    marker: {
                                        radius: 2,
                                    },
                                    lineWidth: 1.5,
                                },
                            },
                        }
                    }]
                }
            };

            // 渲染 Highcharts 图表
            chart = Highcharts.chart('stock-performance-chart', chartOptions);
        }

        // 清理函数，销毁图表实例，防止内存泄漏
        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [GraphData, MarketPerformanceUpdateTime, formatDate]);

    return (
        <Paper
            id="marketPerformance"
            elevation={2}
            sx={{
                p: 3,
                borderRadius: '12px',
                backgroundColor: '#f7f7f7',
                boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                mb: 4,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TrendingUp sx={{ mr: 1, color: '#ff4081', fontSize: '28px' }} />
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 'bold',
                            color: '#ff4081',
                        }}
                    >
                        市场表现
                    </Typography>
                </Box>
                <Typography variant="caption" color="textSecondary">
                    更新日期：{formatDate(MarketPerformanceUpdateTime)}
                </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <ReactMarkdown>{MarketPerformance || '暂无市场表现信息'}</ReactMarkdown>

            {/* 集成 Highcharts 绘制股票走势图 */}
            {GraphData && GraphData.StockPerformance && GraphData.StockPerformance.Dates.length > 0 && GraphData.StockPerformance.Close.length > 0 ? (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <div id="stock-performance-chart" style={{ height: '400px', width: '100%' }}></div>
                </Box>
            ) : (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 4 }}>
                    暂无股票表现数据
                </Typography>
            )}
        </Paper>
    );
};

export default MarketSection;
