import React, { useEffect } from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Contact() {
    useEffect(() => {
        document.body.classList.add('loaded');
        return () => {
            document.body.classList.remove('loaded');
        };
    }, []);

    return (
        <div className="contact-content">
            <h2>联系我们</h2>
            <p>如果您有任何问题或需要帮助，请通过以下方式联系我们：</p>
            <div className="contact-details">
                <div className="contact-item">
                    <h3><FontAwesomeIcon icon={faPhone} /> 电话</h3>
                    <p className="non-clickable">+86 13532088768</p>
                </div>
                <div className="contact-item">
                    <h3><FontAwesomeIcon icon={faEnvelope} /> 邮箱</h3>
                    <p className="non-clickable">customer@infiniedge.tech</p>
                </div>
                <div className="contact-item">
                    <h3>社交媒体</h3>
                    <p>
                        <a href="https://www.linkedin.com/company/infinite-edge-ai/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} className="contact-icon" /> LinkedIn
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Contact;
