import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function SidebarHeader({ isOpen, handleToggleSidebar }) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 56,
                paddingLeft: 2,
                background: 'linear-gradient(90deg, #f0f8ff 0%, #d6eaff 100%)', // 更浅的渐变背景
                clipPath: 'ellipse(100% 100% at 50% 50%)', // 使用 clip-path 裁剪成椭圆形
                overflow: 'hidden', // 确保内容不会溢出
            }}
        >
            {isOpen && (
                <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '150px' }}>
                    <img
                        src="/logo/favicon_io/android-chrome-192x192-removebg-preview.png"
                        srcSet="/logo/favicon_io/android-chrome-192x192-removebg-preview.png 2x"
                        alt="Logo"
                        style={{ height: 40, marginRight: '12px', borderRadius: '50%' }} // 圆形Logo
                    />
                    <Typography
                        variant="h6"
                        sx={{
                            fontFamily: 'Poppins, sans-serif',
                            fontWeight: '600',
                            marginLeft: '8px',
                            letterSpacing: '0.1rem',
                            maxWidth: isOpen ? '100%' : '0',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            transition: 'max-width 0.3s ease-in-out, color 0.3s ease-in-out', // 平滑过渡效果
                            color: '#000', // 深色字体，与浅色背景对比
                        }}
                    >
                        ZiStock
                    </Typography>
                </Box>
            )}
            <IconButton
                onClick={handleToggleSidebar}
                style={{
                    marginRight: '16px',
                    marginLeft: isOpen ? '0px' : '-8px',
                    transition: 'all 0.3s ease-in-out',
                    backgroundColor: '#fff', // 白色背景的图标按钮
                    borderRadius: '50%', // 圆形按钮
                }}
                onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.1)'; }} // 悬停放大效果
                onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)'; }}  // 取消放大
            >
                {isOpen ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ display: 'flex', alignItems: 'center', color: '#3a7bd5' }} // 保持渐变的深蓝色
                    >
                        <path d="M11 18L6 12L11 6" />
                        <path d="M11 12H19" />
                        <path d="M19 2V22" />
                    </svg>
                ) : (
                    <MenuIcon
                        style={{
                            fontSize: isOpen ? 28 : 24,
                            color: isOpen ? '#3a7bd5' : '#000',
                            transition: 'color 0.3s ease-in-out, transform 0.3s ease-in-out', // 图标颜色和大小的过渡
                        }}
                    />
                )}
            </IconButton>
        </Box>
    );
}

export default SidebarHeader;
